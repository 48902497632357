import landInfrastructure from "../images/activities/land_infrastructure.png";
import classificationChange from "../images/activities/classification_change.png";
import thirdParty from "../images/activities/third_party.png";
import waterResource from "../images/activities/water_resource.png";
import landUseCover from "../images/activities/land_use_cover.png";
import disasterResponse from "../images/activities/disaster_response.png";

export const getLabelByValue = (map, value) => {
  const item = map.find((type) => type.value === value);
  return item ? item.label : null;
};

export const mainObjectives = [
  { label: "Evaluate an existing structure", value: "evaluate" },
  { label: "Assess environmental impact", value: "assess" },
  { label: "The environmental context", value: "context" },
]

export const structureTypes = [
  { label: "Bridges/Viaducts", value: "ponti_viadotti" },
  { label: "Tunnels", value: "gallerie" },
  { label: "Road section on embankment", value: "tratto_stradale_rilevato" },
  { label: "Road section in a cutting", value: "tratto_stradale_trincea" },
  { label: "Retaining structure", value: "opera_sostegno" },
  { label: "Other", value: "altro" },
];

export const structuralIssues = [
  { label: "Material Degradation", value: "material_degradation" },
  { label: "Foundation Settling", value: "foundation_settling" },
  { label: "Cracks and Fractures", value: "cracks_fractures" },
  { label: "Vibrations and Impacts", value: "vibrations_impacts" },
  { label: "Damage from External Events", value: "damage_from_external_events" },
  { label: "Stability and Structural Failure Issues", value: "stability_structural_failure_issues" },
];

export const dataTypes = [
  { label: "InSAR", value: "insar" },
  { label: "LiDAR", value: "lidar" },
  { label: "Optical", value: "optical" },
  { label: "GPS", value: "gps" },
  { label: "Inclinometers", value: "inclinometers" },
  { label: "Piezometers", value: "piezometers" },
  { label: "Other", value: "other" },
];

export const requestOptions = [
  { title: "Land and Infrastructure Assessment and Monitoring", image: landInfrastructure, disabled: false },
  { title: "Classification and Change Detection", image: classificationChange, disabled: true },
  { title: "Third Party Interference Detection", image: thirdParty, disabled: true },
  { title: "Water Resource Management", image: waterResource, disabled: true },
  { title: "Land Use and Land Cover", image: landUseCover, disabled: true },
  { title: "Disaster Response & Emergency Management", image: disasterResponse, disabled: true },
];

export const requestPriority = ["low", "medium", "high"];

export const defaultOpenDataSources = [
  "Dati Progetto IFFI",
  "Alluvioni Classi di Rischio (PGRA)",
  "Pluviometri",
  "Modello di elevazione digitale (DEM)",
  "Layer Classificazione Sismica",
  "Layer erosione costiera",
  "Aree a potenziale rischio significativo di alluvione (APSFR)",
  "Bacini idrografici principali e secondari",
  "Carta geologica d'Italia",
  "Cartografia di base - IGM 25.000",
  "PAI - Pericolosita' idrogeologica",
  "PAI - Rischio idrogeologico",
  "MOISTURE INDEX",
  "NDWI",
  "NDVI",
  "SWIR",
  "False-color-urban",
];

// existing issues

export const landslideSpeed = [
  { value: "landslide_speed_extemely_rapid", label: "Extremely rapid (5 m/s)" },
  { value: "landslide_speed_very_rapid", label: "Very rapid (3 m/min)" },
  { value: "landslide_speed_rapid", label: "Rapid (1.8 m/h)" },
  { value: "landslide_speed_moderate", label: "Moderate (13 m/month)" },
  { value: "landslide_speed_slow", label: "Slow (1.6 m/year)" },
  { value: "landslide_speed_very_slow", label: "Very slow (16 mm/year)" },
  { value: "landslide_speed_extemely_slow", label: "Extremely slow (<16 mm/year)" },
];

export const landslideActivityStatus = [
  { value: "landslide_activity_status_active", label: "Active" },
  { value: "landslide_activity_status_suspended", label: "Suspended" },
  { value: "landslide_activity_status_inactive", label: "Inactive" },
  { value: "landslide_activity_status_dormant", label: "Dormant" },
];

export const landslideMovementType = [
  { value: "landslide_movement_type_fall", label: "Fall" },
  { value: "landslide_movement_type_topple", label: "Topple" },
  { value: "landslide_movement_type_slide", label: "Slide" },
  { value: "landslide_movement_type_spread", label: "Spread" },
  { value: "landslide_movement_type_flow", label: "Flow" },
];

export const coastalErosionSpeed = [
  { value: "coastal_erosion_speed_weak_erosion", label: "Weak erosion (between -0.5 and -1.0 m/yr)" },
  { value: "coastal_erosion_speed_intense_erosion", label: "Intense erosion (between -1.0 and -3.0 m/yr)" },
  { value: "coastal_erosion_speed_severe_erosion", label: "Severe erosion (between -3.0 and -5.0 m/yr)" },
  { value: "coastal_erosion_speed_extreme_erosion", label: "Extreme erosion (greater than -5.0 m/yr)" },
];

export const coastalErosionActivityStatus = [
  { value: "coastal_erosion_activity_status_active", label: "Active" },
  { value: "coastal_erosion_activity_status_inactive", label: "Inactive" },
];

export const coastalErosionCause = [
  { value: "coastal_erosion_cause_natural", label: "Natural" },
  { value: "coastal_erosion_cause_man_caused", label: "Man-caused" },
];

export const floodingType = [
  { value: "flooding_type_flash_floods", label: "Flash floods" },
  { value: "flooding_type_slow_floods", label: "Slow floods" },
  { value: "flooding_type_seasonal_floods", label: "Seasonal floods" },
];

export const subsidenceCause = [
  { value: "subsidence_cause_natural", label: "Natural" },
  { value: "subsidence_cause_bradyseism", label: "Bradyseism" },
  { value: "subsidence_cause_sinkhole", label: "Sinkhole" },
  { value: "subsidence_cause_others", label: "Others" },
  { value: "subsidence_cause_man_caused", label: "Man-caused" },
  { value: "subsidence_cause_Tunnel excavation", label: "Tunnel excavation" },
  { value: "subsidence_cause_water_extraction", label: "Water extraction" },
  { value: "subsidence_cause_oil_gas_production", label: "Oil and Gas production" },
];

export const liquefactionCause = [
  { value: "liquefaction_cause_static", label: "Static" },
  { value: "liquefaction_cause_earthquake_induced", label: "Earthquake-induced" },
];

export const geohazards = [
  {
    value: "landslide",
    label: "Landslide",
    properties: [
      {
        value: "landslide_speed",
        label: "Speed"
      },
      {
        value: "landslide_activity_status",
        label: "Activity Status"
      },
      {
        value: "landslide_movement_type",
        label: "Movement Type"
      }
    ]
  }, {
    value: "coastal_erosion",
    label: "Coastal Erosion",
    properties: [
      {
        value: "coastal_erosion_speed",
        label: "Speed"
      },
      {
        value: "coastal_erosion_activity_status",
        label: "Activity Status"
      },
      {
        value: "coastal_erosion_cause",
        label: "Cause"
      }
    ]
  }, {
    value: "flooding",
    label: "Flooding",
    properties: [
      {
        value: "flooding_type",
        label: "Type"
      }
    ]
  }, {
    value: "subsidence",
    label: "Subsidence",
    properties: [
      {
        value: "subsidence_cause",
        label: "Cause"
      }
    ]
  }, {
    value: "liquefaction",
    label: "Liquefaction",
    properties: [
      {
        value: "liquefaction_cause",
        label: "Cause"
      }
    ]
  }, {
    value: "seismic_activity",
    label: "Seismic activity",
    properties: []
  }
];
