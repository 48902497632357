import React, { useState } from "react";
import Modal from "./Modal";
import { client } from "../services/api";
import { toast } from "react-toastify";

function ModalActivity({ isOpen, onClose, onDone }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [members, setMembers] = useState([]);
  const [newMember, setNewMember] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAddMember = async () => {
    if (!newMember) {
      toast.error("Please enter an email address!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    try {
      // Check if the user exists
      const response = await client.checkUser(newMember);
      const { exists } = response.data;

      if (exists) {
        if (!members.includes(newMember)) {
          setMembers([...members, newMember]);
        } else {
          toast.info("This member is already added.", {
            position: "top-right",
            autoClose: 3000,
          });
        }
        setNewMember("");
      } else {
        // Show error if the user does not exist
        toast.error("User does not exist!", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Error checking user:", error);
      toast.error("Failed to check user. Please try again.", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const handleRemoveMember = (member) => {
    setMembers(members.filter((m) => m !== member));
  };

  const handleDone = async () => {
    if (!name.trim()) {
      toast.error("Project name is required.", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const projectData = { name, description, members };
      const response = await client.createProject(projectData);
      const { inserted_id } = response.data;

      toast.success("Project created successfully!", {
        position: "top-right",
        autoClose: 3000,
      });

      setTimeout(() => {
        // Clear the form
        setName("");
        setDescription("");
        setMembers([]);

        onDone({ ...projectData, _id: inserted_id });
        onClose();
      }, 100);
    } catch (error) {
      console.error("Error creating project:", error);
      toast.error("Failed to create project. Please try again.", {
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="large">
      <h6>Create a Project</h6>
      <div className="form-group">
        <label>Project Name</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter project name"
        />
      </div>
      <textarea
        placeholder="Description (less than 100 words)"
        className="description"
        style={{ width: "100%" }}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <div className="form-group">
        <label>Add Members</label>
        <input
          type="email"
          value={newMember}
          onChange={(e) => setNewMember(e.target.value)}
          placeholder="Enter email"
        />
        <button className="dark" onClick={handleAddMember}>
          Add
        </button>
      </div>
      <div className="members-list">
        {members.map((member, index) => (
          <span key={index} className="member">
            {member}
            <button onClick={() => handleRemoveMember(member)}>✕</button>
          </span>
        ))}
      </div>
      <div className="modal-actions">
        <button className="outline" onClick={onClose} disabled={isSubmitting}>
          Cancel
        </button>
        <button
          className="dark"
          onClick={handleDone}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Creating..." : "Done"}
        </button>
      </div>
    </Modal>
  );
}

export default ModalActivity;
