import React, { createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState({
    'tsView': 'basic',
  });

  useEffect(() => {
    const token = localStorage.getItem(process.env.REACT_APP_COOKIE_NAME);
    if (token) {
      try {
        const decodedUser = jwtDecode(token);
        setUser(decodedUser);
      } catch (error) {
        console.error("Failed to decode token:", error);
        logout();
      }
    }
    setLoading(false);
  }, []);

  const login = async (email, password) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_API}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.error || "Login failed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      const { token } = await response.json();
      const decodedUser = jwtDecode(token);

      if(!!decodedUser.termsAndConditions) {
        localStorage.setItem(process.env.REACT_APP_COOKIE_NAME, token);
        setUser(decodedUser);
      }

      return decodedUser;
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again.", {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  const acceptTerms = (email) => {
    return fetch(`${process.env.REACT_APP_URL_API}/auth/accept-terms`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem(process.env.REACT_APP_COOKIE_NAME);
  };

  return (
    <AuthContext.Provider value={{
      user,
      login,
      acceptTerms,
      logout,
      loading,
      settings,
      setSettings,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
