import React from "react";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {
  getLabelByValue, landslideSpeed, landslideActivityStatus,
  landslideMovementType, coastalErosionSpeed, coastalErosionActivityStatus,
  coastalErosionCause, floodingType, subsidenceCause, liquefactionCause
} from "../constants/formOptions";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

const propertyOptions = {
  landslide_speed: landslideSpeed,
  landslide_activity_status: landslideActivityStatus,
  landslide_movement_type: landslideMovementType,
  coastal_erosion_speed: coastalErosionSpeed,
  coastal_erosion_activity_status: coastalErosionActivityStatus,
  coastal_erosion_cause: coastalErosionCause,
  flooding_type: floodingType,
  subsidence_cause: subsidenceCause,
  liquefaction_cause: liquefactionCause,
};

function getPropertyOptions(property) {
  return propertyOptions[property.value] ?? [];
}

export const GeohazardProperty = ({ geohazard, property, formData, handleChange, disabled }) => {
  const propertyOptions = getPropertyOptions(property);
  const selectedValues = formData.geohazards[geohazard.value]?.[property.value]?.value || '';

  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <InputLabel id={`${property.label}-label`}>{property.label}</InputLabel>
      <Select
        labelId={`${property.label}-label`}
        //multiple
        disabled={disabled}
        value={selectedValues}
        onChange={(e) => handleChange(geohazard, property, e.target.value)}
        input={<OutlinedInput label={property.label} />}
        renderValue={(selected) =>
          [selected].map((i) => getLabelByValue(propertyOptions, i)).join(', ')
        }
        MenuProps={MenuProps}
      >
        {propertyOptions.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            <Checkbox checked={selectedValues.includes(value)} />
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
