import React from 'react';

function MenuItem({ name, onClick }) {
  return (
    <div className="menu-item cursor" onClick={onClick}>
      {name}
    </div>
  );
}

export default MenuItem;