import React, { useEffect, useState } from "react";
import { client } from "../services/api";
//import Status from '../images/status.svg'
import Requests from "./Requests";

function ActivityItem({ project }) {
  const [requests, setRequests] = useState([]);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    client.getRequestsByProject(project._id)
      .then((resp) => setRequests(resp.data))
      .catch((err) => console.error(err.message));
    client.getNotificationsByProject(project._id)
      .then((resp) => setNotifications(resp.data))
      .catch((err) => console.error(err.message));
    // eslint-disable-next-line
  }, []);

  return (
    <div className="activity-item">
      <h5>{project.name}</h5>
      <div className="section-bubble">
        {project.description}
      </div>
      {requests?.length > 0 && (
        <div className="section-bubble">
          <h6>Ongoing Requests</h6>
          <Requests requests={requests} min={true} />
        </div>
      )}
      {notifications?.length > 0 && (
        <div className="section-bubble">
          <h6>
            Notifications <span style={{ color: "red" }}>({notifications.length})</span>
          </h6>
          {notifications.map((notification) => (
            <div key={notification._id} className="notification-item">
              <div className="icon red"></div>
              <div className="data">
                <div>{notification.datetime}</div>
                <div>{notification.description}</div>
              </div>
              <a href="/">More details</a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ActivityItem;
