import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalTermsAndConditions from "../components/ModalTermsAndConditions";
import AuthContext from '../context/AuthContext';
import Logo from "../images/logo.svg";
import { toast } from "react-toastify";

const Login = () => {
  const { login, acceptTerms, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isModalTermsAndConditionsOpen, setModalTermsAndConditionsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  const handleLogin = async (e) => {
    const user = await login(email, password);

    if (user) {
      if (!user.termsAndConditions) {
        setModalTermsAndConditionsOpen(true);
      } else {
        navigate('/');
      }
    }
  };
  
  const handleAcceptTerms = async (e) => {
    const result = await acceptTerms(email);
    if(result.ok) {
      handleLogin();
    } else {
      toast.error("Error while accepting terms and conditions!", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: "100vh",
        fontFamily: "Arial, sans-serif",
        backgroundColor: "#010E2D",
      }}
    >
      <ModalTermsAndConditions isOpen={isModalTermsAndConditionsOpen} onClose={() => setModalTermsAndConditionsOpen(false)} onDone={handleAcceptTerms} />
      {/* Left Side */}
      <div
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "0 40px",
          color: "#FFFFFF",
        }}
      >
        {/* Logo */}
        <h1 style={{ marginBottom: "40px", fontSize: "36px" }}>
          <img className="logo" src={Logo} alt="Titan4 Logo" />
        </h1>
        

        {/* Form */}
        <div style={{ width: "100%", maxWidth: "400px" }}>
          {/* Email Input */}
          <div style={{ marginBottom: "20px" }}>
            <label htmlFor="email" style={{ display: "block", marginBottom: "8px" }}>
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={handleKeyDown}
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
          </div>

          {/* Password Input */}
          <div style={{ marginBottom: "20px", position: "relative" }}>
            <label htmlFor="password" style={{ display: "block", marginBottom: "8px" }}>
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyDown}
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
          </div>

          {/* Forgot Password
          <a
            href="/"
            style={{
              display: "block",
              marginBottom: "20px",
              color: "#F68C23",
              textDecoration: "none",
              textAlign: "right",
            }}
          >
            Forgot your password?
          </a> */}

          {/* Login Button */}
          <button
            onClick={handleLogin}
            style={{
              width: "100%",
              padding: "10px",
              backgroundColor: "#F68C23",
              color: "#FFFFFF",
              border: "none",
              borderRadius: "4px",
              fontSize: "16px",
              cursor: "pointer",
            }}
          >
            Log In
          </button>
        </div>

        {/* Footer */}
        <div style={{ marginTop: "40px", fontSize: "14px" }}>
          Have a Problem?{" "}
          <a
            href="mailto:info@titan4.it"
            target="_blank"
            rel="noreferrer"
            style={{
              color: "#F68C23",
              textDecoration: "none",
            }}
          >
            Contact Us
          </a>
        </div>
      </div>

      {/* Right Side (Placeholder) */}
      <div style={{ width: "50%", backgroundImage: `url('insarBg.jpg')`, backgroundSize: 'cover' }}></div>
    </div>
  );
};

export default Login;
