import React, { useState } from "react";
import { ObjectId } from 'bson';
import { toast } from "react-toastify";
import Modal from "./Modal";

function ModalAddDataURL({ isOpen, onClose, onDone, dataURL }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleDone = () => {
    if (!name.trim() || !url) {
      toast.error("Please provide both name and url for the structure.", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    onDone({ _id: new ObjectId(), name, description, url });
    handleClose();
  };

  const resetForm = () => {
    setName("");
    setDescription("");
    setURL("");
  };

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} className="large">
      <div className="modal-add-data-file">
        <h6>Add a data URL</h6>
        Name:<br />
        <input type="text" defaultValue={dataURL?.name} disabled={!!dataURL} onChange={(e) => setName(e.target.value)} />
        <br />
        <textarea
          placeholder="Description (less than 100 words)"
          className="description"
          onChange={(e) => setDescription(e.target.value)}
          defaultValue={dataURL?.description}
          disabled={!!dataURL}
        />
        <br />
        URL:<br />
        <input type="text" defaultValue={dataURL?.url} disabled={!!dataURL} onChange={(e) => setURL(e.target.value)} />
      </div>

      <div className="modal-actions" style={{ justifyContent: 'flex-end' }}>
        {!!dataURL ? (
          <>
            <button className="button-outline outline" onClick={handleClose}>
              Close
            </button>
          </>
        ) : (
          <>
            <button className="button-outline outline" onClick={handleClose}>
              Cancel
            </button>
            <button className="button-dark" onClick={handleDone}>
              Done
            </button>
          </>
        )}
      </div>
    </Modal>
  );
}

export default ModalAddDataURL;
