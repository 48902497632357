import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

function SidebarButton({ title, icon, to, disabled }) {
  const navigate = useNavigate();

  const handleClick = () => {
    to && navigate(to);
  };

  const location = useLocation();
  const { pathname } = location;
  const active = pathname.endsWith(to);
  return (
    <div onClick={handleClick} className={classNames("sidebar-button", active && 'active', !active && disabled && 'disabled')}>
      {icon}
      <span>{title}</span>
    </div>
  );
}

export default SidebarButton;