import React from "react";
import { geohazards } from "../../constants/formOptions";
import { GeohazardProperty } from "../GeohazardProperty";

function StepExistingIssue({ formData, updateFormData }) {

  const handleGeohazardsChange = (geohazard) => {
    const updatedGeohazards = { ...formData.geohazards };
    if (updatedGeohazards[geohazard.value]) {
      delete updatedGeohazards[geohazard.value];
    } else {
      updatedGeohazards[geohazard.value] = { };
    }
    updateFormData("geohazards", updatedGeohazards);
  };

  const handleGeohazardsPropertyChange = (geohazard, property, value) => {
    const updatedGeohazards = { ...formData.geohazards };
    if (!updatedGeohazards[geohazard.value]) {
      updatedGeohazards[geohazard.value] = { };
    }
    updatedGeohazards[geohazard.value][property.value] = { value };
    updateFormData("geohazards", updatedGeohazards);
  };

  return (
    <div>
      <h3>Existing Issue</h3>
      <h6>Is there a known geohazard in the area?</h6>
      <div className="section-bubble">
        <table className="existing-issue">
          <tbody>
            {geohazards.map((geohazard, index) => (
              <tr key={index}>
                <td>
                  <label>
                    <input
                      type="checkbox"
                      checked={geohazard.value in formData.geohazards}
                      onChange={() => handleGeohazardsChange(geohazard)}
                    />
                    {geohazard.label}
                  </label>
                </td>
                {geohazard.properties.map((property, index_p) => (
                  <td key={`${index}-${index_p}`}>
                    <GeohazardProperty
                      disabled={!(geohazard.value in formData.geohazards)}
                      geohazard={geohazard}
                      property={property}
                      formData={formData}
                      handleChange={handleGeohazardsPropertyChange}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default StepExistingIssue;
