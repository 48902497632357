import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LinearProgressWithLabel } from './LinearProgressWithLabel';
import priorityLow from "../images/priority_low.png";
//import priorityMedium from "../images/priority_medium.png";
//import priorityHigh from "../images/priority_high.png";

function Requests({ requests, min }) {
  const navigate = useNavigate();
  return (
    <div className="requests">
      {requests.map((request) => (
        <div key={request._id} className={min ? "" : "section-bubble"} style={{ cursor: 'pointer' }} onClick={() => navigate(`/requests/${request._id}`)}>
          <div className="request-item">
            {min && <img src={priorityLow} alt="low priority" className="request-priority-img" />}
            <div>ID {request._id}</div>
            <div>{request.project.name}</div>
            <div>{new Date(request.createDate).toLocaleDateString()}</div>
            <LinearProgressWithLabel value={Number(request.progress) || 10} />
            <div>{request.form.requestType.join(", ")}</div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Requests;