import React, { useState, useRef, useEffect } from "react";
import Modal from "./Modal";

function ModalTermsAndConditions({ isOpen, onClose, onDone }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const contentRef = useRef(null);

  const checkIfScrolledToBottom = () => {
    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      setIsScrolledToBottom(scrollTop + clientHeight >= scrollHeight - 100);
    }
  };

  useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.addEventListener("scroll", checkIfScrolledToBottom);
      checkIfScrolledToBottom();
    }
  
    return () => {
      if (contentElement) {
        contentElement.removeEventListener("scroll", checkIfScrolledToBottom);
      }
    };
  }, [isOpen]);

  const handleAccept = () => {
    if (isScrolledToBottom) {
      setIsSubmitting(true);
      onDone();
      setIsSubmitting(false);
      onClose();
    } else {
      contentRef.current.scrollTo({
        top: contentRef.current.scrollTop + contentRef.current.clientHeight,
        behavior: "smooth",
      });
    }
  };

  if (!isOpen) return null;

  return (
    <Modal hideClose isOpen={isOpen} className="large">
      <div ref={contentRef} style={{ color: 'black', overflow: 'auto', height: '350px' }}>
        <h5>Terms and Conditions of Service</h5>
        <h6>1. Introduction</h6>
        Welcome to Titan4. These Terms and Conditions govern the use of our SaaS platform for monitoring infrastructures using satellite data. By using our service, you fully agree to these Terms and Conditions.
        <h6>2. Definitions</h6>
        <ul>
          <li><b>"Provider":</b> Titan4 srl, with registered office at Via Appia Nuova, 359 - 00181 Rome Italy.</li>
          <li><b>"User":</b> Any natural or legal person using the service.</li>
          <li><b>"Service":</b> The software-as-a-service (SaaS) platform for monitoring infrastructures, such as roads, railways and energy networks,  through satellite.</li>
        </ul>
        <h6>3. Scope of the Agreement</h6>
        The Provider offers a monitoring service based on satellite data, accessible via a cloud platform. Users can access the service for analysis, reporting, and monitoring of infrastructures.
        <h6>4. Access to the Service</h6>
        4.1 Access to the service requires registration and acceptance of these Terms and Conditions.<br />
        4.2 The User is responsible for the accuracy and truthfulness of the data provided during registration.<br />
        4.3 The Provider reserves the right to suspend or terminate access in case of any violation of these Terms.
        <h6>5. Use of the Service</h6>
        5.1 The Service is provided "as is" and "as available."<br />
        5.2 The User agrees not to use the service for unlawful, inappropriate, or non-compliant purposes under applicable laws.<br />
        5.3 The User is responsible for interpreting the data and analyses provided by the Service. The Provider does not guarantee that the analyses are error-free or meet all the User's specific requirements.
        <h6>6. Liability</h6>
        6.1 The Provider is not liable for direct, indirect, incidental, or consequential damages arising from the use or inability to use the service.<br />
        6.2 The User acknowledges that the service relies on satellite data provided by third parties and accepts any limitations or inaccuracies resulting from this.
        <h6>7. Data Processing</h6>
        The processing of personal data is carried out in accordance with EU Regulation 2016/679 (GDPR). Please refer to our <b>Privacy Policy</b> for more details.
        <h6>8. Intellectual Property</h6>
        8.1 All rights related to the software, design, trademarks, and content provided through the Service are the exclusive property of the Provider or its licensors.<br />
        8.2 Copying, distributing, modifying, or using the Service without written authorization is prohibited.
        <h6>9. Duration and Termination</h6>
        9.1 These Terms remain valid as long as the User uses the Service.<br />
        9.2 The Provider may terminate the agreement at any time in case of violation by the User.
        <h6>10. Governing Law and Jurisdiction</h6>
        These Terms are governed by Italian law. Any disputes arising from or related to these Terms will be subject to the exclusive jurisdiction of the Court of Rome - Italy.
        <h6>11. Changes to the Terms</h6>
        These Terms are governed by Italian law. Any disputes arising from or related to these Terms will be subject to the exclusive jurisdiction of the Court of Rome - Italy.
        <h6>12. Contact Information</h6>
        For further information or clarifications, please contact the Provider at <a style={{ display: 'contents' }}href="mailto:info@titan4.it" target="_blank" rel="noreferrer">info@titan4.it</a> or Via dell'Arte, 25 00144 Rome - Italy.
      </div>
      <div className="modal-actions">
        <button className="outline" onClick={onClose} disabled={isSubmitting}>
          Decline
        </button>
        <button
          className="dark"
          onClick={handleAccept}
          disabled={isSubmitting}
        >
          {isScrolledToBottom ? 'Accept' : 'Scroll'}
        </button>
      </div>
    </Modal>
  );
}

export default ModalTermsAndConditions;
