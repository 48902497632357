import React, { useState, useEffect } from "react";
import { client } from "../services/api";
import { ObjectId } from 'bson';
import { toast } from "react-toastify";
import Modal from "./Modal";
import { dataTypes } from "../constants/formOptions";
import { LinearProgress, Box, Typography } from "@mui/material";

function ModalAddDataFile({ isOpen, onClose, onDone, projectId, dataFile }) {
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [upload, setUpload] = useState(null);
  const [shape, setShape] = useState(null);
  const [description, setDescription] = useState("");
  const [existingUpload, setExistingUpload] = useState(null);
  const [existingShapeFile, setExistingShapeFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadProgressDescription, setUploadProgressDescription] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const handleClose = () => {
    if (isUploading) return;
    if (shape) {
      if (window.confirm("The shape file uploaded will be deleted, continue?")) {
        client.deleteGeometry(projectId, shape)
          .then(() => toast.success("Shape file deleted successfully!"));
      } else {
        return;
      }
    }
    resetForm();
    onClose();
  };

  const handleDone = () => {
    if (!name.trim() || !upload) {
      toast.error("Please provide both name and dataFile for the structure.", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    onDone({ _id: new ObjectId(), name, type, upload, description, shape });
    if (isUploading) return;
    resetForm();
    onClose();
  };

  useEffect(() => {
    if (dataFile) {
      setType(dataFile.type);
      client.getUpload(dataFile.upload).then(result => {
        if (result.statusText === 'OK') {
          setExistingUpload(result.data);
        }
      });
      client.getDataFile(dataFile.shape).then(result => {
        if (result.statusText === 'OK') {
          setExistingShapeFile(result.data);
        }
      });
    }
  }, [dataFile]);

  const resetForm = () => {
    setName("");
    setDescription("");
    setType("");
    setUpload(null);
    setShape(null);
  };

  const handleFileUpload = async (file, setFileState) => {
    if (!file) {
      toast.error("Please select a file to upload.");
      return;
    }
    if (!projectId) {
      toast.error("Please select a project to upload.");
      return;
    }

    const MAX_FILE_SIZE = 5 * 1024 * 1024;
    if (file.size > MAX_FILE_SIZE) {
      toast.error("File size exceeds 5MB. Please upload a smaller file.", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    const fileName = file.name.replace(/\.[^/.]+$/, "").replace(/_/g, " ");

    setIsUploading(true);
    setUploadProgress(0);
    setUploadProgressDescription("");

    try {
      let currentStep = 0;
      let totalSteps = 1;
      client.uploadGeometry(file, fileName, projectId, (progress) => {
        if (progress.status === "started") {
          totalSteps = progress.steps;
        } else if (progress.status === "progress") {
          currentStep++;
          setUploadProgress((currentStep / totalSteps) * 100);
          setUploadProgressDescription(progress.description);
        } else if (progress.status === "done") {
          setFileState(progress.result.datafile_id);
          toast.success("File uploaded successfully!");
          setUploadProgress(100);
          setUploadProgressDescription("");
          setIsUploading(false);
        } else if (progress.status === "error") {
          toast.error("File upload error: " + progress.message);
          setUploadProgress(0);
          setUploadProgressDescription("");
          setIsUploading(false);
        }
      });
    } catch (error) {
      console.error("File upload error:", error);
      toast.error("File upload failed.");
      setIsUploading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} className="large">
      <div className="modal-add-data-file">
        <h6>Add a data file</h6>
        Data Name:<br />
        <input type="text" defaultValue={dataFile?.name} onChange={(e) => setName(e.target.value)} disabled={!!dataFile} />
        <br />
        {!!dataFile ? (
          <>
            Data file: {existingUpload && <a href={`${process.env.REACT_APP_URL_API}/upload/${existingUpload._id}/download`} target="_blank" rel="noreferrer">{existingUpload.filename}</a>}
          </>
        ) : (
          <>
            Select the data file:<br/>
            <input
              type="file"
              className="data-file"
              style={{ width: 'auto', height: 'auto' }}
              onChange={(e) => setUpload(e.target.files[0])} />
          </>
        )}
        <br />
        Select the data type:
        <div className="radio-group">
          {dataTypes.map((option) => (
            <label key={option.value}>
              <input
                type="radio"
                name="data_type"
                value={option.value}
                checked={type === option.value}
                onChange={(e) => setType(e.target.value)}
                disabled={!!dataFile}
              />
              {option.label}
            </label>
          ))}
        </div>
        {!!dataFile ? (
          <>
            Shape file: {existingShapeFile?.upload && <a href={`${process.env.REACT_APP_URL_API}/upload/${existingShapeFile?.upload._id}/download`} target="_blank" rel="noreferrer">{existingShapeFile?.upload.filename}</a>}
          </>
        ) : (
          <>
            Specify the position of the existing data:<br/>
            <input
              type="file"
              className="data-file"
              style={{ width: 'auto', height: 'auto' }}
              onChange={(e) => handleFileUpload(e.target.files[0], setShape)}
              accept=".geojson,.GEOJSON,.kml,.KML,.kmz,.KMZ,.zip,.ZIP"
            />
            {isUploading && (
              <Box sx={{ width: "100%", mt: 2 }}>
                <LinearProgress variant="determinate" value={uploadProgress} />
                <Typography variant="body2" color="text.secondary" align="center">
                  {`${Math.round(uploadProgress)}%`} {uploadProgressDescription && `- ${uploadProgressDescription}`}
                </Typography>
              </Box>
            )}
          </>
        )}
        <textarea
          placeholder="Description (less than 100 words)"
          className="description"
          onChange={(e) => setDescription(e.target.value)}
          defaultValue={dataFile?.description}
          disabled={!!dataFile}
        />
      </div>

      <div className="modal-actions" style={{ justifyContent: 'flex-end' }}>
        {!!dataFile ? (
          <>
            <button className="button-outline outline" onClick={handleClose}>
              Close
            </button>
          </>
        ) : (
          <>
            <button className="button-outline outline" onClick={handleClose}>
              Cancel
            </button>
            <button className="button-dark" onClick={handleDone}>
              Done
            </button>
          </>
        )}
      </div>
    </Modal>
  );
}

export default ModalAddDataFile;
