import React, { useEffect, useState } from "react";
import center from "@turf/center";
import area from "@turf/area";
import priorityLow from "../../images/priority_low.png";
import priorityMedium from "../../images/priority_medium.png";
import priorityHigh from "../../images/priority_high.png";
import { requestPriority } from "../../constants/formOptions";

function StepConfirm({ formData, updateFormData, setCurrentStep }) {
  const [geometryInfo, setGeometryInfo] = useState({ center: null, area: null });

  useEffect(() => {
    const geoCenter = center(formData.areaOfInterest);
    const [lng, lat] = geoCenter.geometry.coordinates;

    const geoArea = area(formData.areaOfInterest) / 1_000_000;

    setGeometryInfo({ center: { lat, lng }, area: geoArea.toFixed(2) });
  }, [formData]);

  return (
    <div className="step-confirm">
      <h3>Request Priority</h3>
      <div className="request-priority-wrapper">
        {requestPriority.map((option, index) => (
          <div
            key={index}
            onClick={() => updateFormData("requestPriority", option)}
            className={"request-priority-container" + (formData.requestPriority === option ? " active" : "")}
          >
            <img
              src={
                option === "low"
                  ? priorityLow
                  : option === "medium"
                  ? priorityMedium
                  : priorityHigh
              }
              alt={option}
              className="request-priority-img"
            />
            <div className="request-priority-text">{option}</div>
          </div>
        ))}
      </div>

      {/* Summary */}
      <h4>Summary</h4>
      <div className="summary">
        <div className="summary-row">
          <span>
            <strong>Area: </strong>{geometryInfo.area && `${geometryInfo.area} km²`} { geometryInfo.center?.lat && <span>{geometryInfo.center?.lat?.toFixed(5)}, {geometryInfo.center?.lng?.toFixed(5)}</span>}
          </span>
          <span>
            <strong>Time: </strong>
            {formData.requestActivityAssessment && `Assessment ${formData.timeOfInterestAssessment?.from || "Not specified"} to ${formData.timeOfInterestAssessment?.to || "Not specified"}`}
            {formData.requestActivityMonitoring && `Monitoring ${formData.timeOfInterest?.from || "Not specified"} to ${formData.timeOfInterest?.to || "Not specified"}`}
          </span>
          <button onClick={() => setCurrentStep(1)} className="edit-button">
            <i className="icon-edit"></i>
          </button>
        </div>
        <div className="summary-row">
          <span>
            <strong>Main Objective:</strong> {formData.selectedObjectives.join(", ") || "Not specified"} ({formData.structures.map((i) => i.type).join(", ") || "None"})
          </span>
          <button onClick={() => setCurrentStep(2)} className="edit-button">
            <i className="icon-edit"></i>
          </button>
        </div>
        <div className="summary-row">
          <span>
            <strong>Existing Issue:</strong> {Object.keys(formData.geohazards).join(", ") || "None"}
          </span>
          <button onClick={() => setCurrentStep(3)} className="edit-button">
            <i className="icon-edit"></i>
          </button>
        </div>
        <div className="summary-row">
          <span>
            <strong>Existing Data:</strong> {formData.dataFiles.map((i) => `${i.name} (${i.type})`).join(", ") || "None"}
          </span>
          <button onClick={() => setCurrentStep(4)} className="edit-button">
            <i className="icon-edit"></i>
          </button>
        </div>
      </div>
      <br /><br />
      <h4>Advanced Details</h4>
      <div className="summary">
        <div className="summary-row" style={{ display: 'block' }}>
          <span><strong>Application:</strong> ND</span><br />
          <span><strong>Direction:</strong> ND</span><br />
          <span><strong>Band and Resolution:</strong> ND</span>
          <button onClick={() => null} className="edit-button" style={{
            float: 'right',
            position: 'relative',
            top: '-15px' }}
          >
            <i className="icon-edit"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default StepConfirm;
