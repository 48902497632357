import axios from "axios";

export const create = (baseURL = process.env.REACT_APP_URL_API) => {
  const api = axios.create({
    baseURL,
    headers: {},
    timeout: 60000,
  });

  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem(process.env.REACT_APP_COOKIE_NAME);
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        localStorage.removeItem(process.env.REACT_APP_COOKIE_NAME);
        window.location.href = "/login";
      }
      return Promise.reject(error);
    }
  );

  // Project Methods
  const getProjects = () => api.get(`/project/list`);
  const getProjectById = (id) => api.get(`/project/${id}`);
  const createProject = (projectData) => api.post(`/project/create`, projectData);

  // Request Methods
  const getRequests = () => api.get(`/request/list`);
  const getRequestById = (id) => api.get(`/request/${id}`);
  const getRequestsByProject = (projectId) => api.get(`/request/project/${projectId}`);
  const createRequest = (requestData) => api.post(`/request/create`, requestData);
  const addRequestAction = (id, actionData) => api.post(`/request/${id}/actions`, actionData);
  const updateRequestAction = (id, actionId, actionData) => api.patch(`/request/${id}/actions/${actionId}`, actionData);
  const deleteRequestStructure = (id, structureId) => api.patch(`/request/${id}/structures/${structureId}`);
  const deleteRequestDataFile = (id, dataFileId) => api.patch(`/request/${id}/dataFiles/${dataFileId}`);
  const deleteRequestDataURL = (id, dataURLId) => api.patch(`/request/${id}/dataURLs/${dataURLId}`);
  const deleteRequest = (id) => api.delete(`/request/${id}`);

  // Notification Methods
  const getNotifications = () => api.get(`/notification/list`);
  const getNotificationsByProject = (projectId) => api.get(`/notification/project/${projectId}`);

  // File Upload Handling
  const getUpload = (uploadId) => api.get(`/upload/${uploadId}`);
  const uploadFile = async (file) => {
    try {
      // Get a signed upload URL from the server
      const { data: uploadUrlResponse } = await api.get(`/upload/uploadUrl`, {
        params: { type: file.type },
      });

      const { uploadUrl, uploadName } = uploadUrlResponse;

      // Upload the file to Google Cloud Storage
      await axios.put(uploadUrl, file, {
        headers: { "Content-Type": file.type },
      });

      // Save file metadata in the database
      const response = await api.post(`/upload/file`, {
        uploadName,
        filename: file.name,
      });

      return response.data;
    } catch (error) {
      console.error("Error during file upload:", error);
      throw error;
    }
  };

  // Geometry Upload Methods
  const uploadGeometry = async (file, fileName, projectId, onProgress) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", fileName);
    formData.append("projectId", projectId);

    try {
      const response = await fetch(`${process.env.REACT_APP_URL_API}/upload/uploadGeometry`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${localStorage.getItem(process.env.REACT_APP_COOKIE_NAME)}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let buffer = "";
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });

        const lines = buffer.split("\n\n");
        buffer = lines.pop();

        for (const line of lines) {
          try {
            const data = JSON.parse(line.trim().split("data: ")[1]);
            onProgress(data);
          } catch (err) {
            console.error("Error parsing progress data:", err);
          }
        }
      }
    } catch (err) {
      console.error("Network error:", err);
      onProgress({ status: "error", message: err.message });
    }
  }
  const deleteGeometry = (projectId, dataFileId) => api.delete(`/upload/deleteGeometry/${projectId}/${dataFileId}`);


  // Upload Management Methods
  const deleteFile = (uploadId) => api.delete(`/upload/file/${uploadId}`);

  // Structure Methods
  /*const createStructure = (structureData) => api.post(`/structure/create`, structureData);
  const deleteStructure = (structureId) => api.delete(`/structure/${structureId}`);*/

  // DataFile Methods
  const createDataFile = (dataFileData) => api.post(`/dataFile/create`, dataFileData);
  const getDataFile = (dataFileId) => api.get(`/dataFile/${dataFileId}`);
  const deleteDataFile = (dataFileId) => api.delete(`/dataFile/${dataFileId}`);

  // Organization Methods
  const getOrganizations = () => api.get(`/organization/list`);
  const createOrganization = (organizationData) => api.post(`/organization/create`, organizationData);

  // User Methods
  const getUsers = () => api.get(`/user/list`);
  const getUserById = (id) => api.get(`/user/${id}`);
  const checkUser = (email) => api.post(`/user/check-user`, { email });

  return {
    getProjects,
    getProjectById,
    createProject,
    getRequests,
    getRequestById,
    getRequestsByProject,
    createRequest,
    addRequestAction,
    updateRequestAction,
    deleteRequestStructure,
    deleteRequestDataFile,
    deleteRequestDataURL,
    deleteRequest,
    getNotifications,
    getNotificationsByProject,
    getUpload,
    uploadFile,
    deleteFile,
    uploadGeometry,
    deleteGeometry,
    createDataFile,
    getDataFile,
    deleteDataFile,
    getOrganizations,
    createOrganization,
    getUsers,
    getUserById,
    checkUser,
    api,
  };
};

export const client = create();
