import React, { useState, useEffect } from "react";
import { client } from "../../services/api";
import classNames from "classnames";
import { requestOptions } from "../../constants/formOptions";
import ModalActivity from "../ModalActivity";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function StepActivitySelection({ formData, updateFormData }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [projects, setProjects] = useState([]);
  useEffect(() => {
    client.getProjects()
      .then((resp) => setProjects(resp.data))
      .catch((err) => console.error(err.message));
  }, []);

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === 'create-new') {
      setIsModalOpen(true);
    } else {
      updateFormData('project', selectedValue);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDone = (newActivity) => {
    setProjects((prev) => [...prev, newActivity]);
    updateFormData('project', newActivity._id);
  };

  return (
    <div>
      <h3>Project Selection</h3>
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select a Project</InputLabel>
          <Select
            value={formData.project}
            label="Select a Project"
            onChange={handleChange}
          >
            {projects.map((project, index) => (
              <MenuItem key={index} value={project._id}>
                {project.name}
              </MenuItem>
            ))}
            <MenuItem value="create-new">+ Create project</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <ModalActivity isOpen={isModalOpen} onClose={handleCloseModal} onDone={handleDone}></ModalActivity>

      <h3>Activities Scope</h3>
      <div className="request-scope-wrapper">
        {requestOptions.map(({ title, image, disabled }, index) => {
          const isEnabled =
            title === "Land and Infrastructure Assessment and Monitoring" ||
            (title === "Land Use and Land Cover" && formData.requestType?.includes("Land and Infrastructure Assessment and Monitoring"));
          return (
          <div
            key={index}
            onClick={() => {
              const updatedRequestTypes = formData.requestType?.includes(title)
                ? formData.requestType.filter((item) => item !== title)
                : [...(formData.requestType || []), title];
          
              updateFormData("requestType", updatedRequestTypes);
            }}
            className={classNames("request-scope-container", { active: formData.requestType?.includes(title), disabled: !isEnabled })}
          >
            <img
              src={image}
              alt={title}
              style={{ width: "100%", height: "100px", objectFit: "cover" }}
            />
            <div className="request-scope-text">{title}</div>
          </div>
        )})}
      </div>
    </div>
  );
}

export default StepActivitySelection;
