import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { client } from "../services/api";
import AuthContext from '../context/AuthContext';
import Dropdown from "./Dropdown";
import Logo from "../images/logo.svg";
import User from "../images/user.png";
//import Switch from "react-js-switch";

function Header() {
  const navigate = useNavigate();
  //const [projects, setProjects] = useState([]);
  const { logout, user, settings, setSettings } = useContext(AuthContext);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    /*client.getProjects()
      .then((resp) => setProjects(resp.data))
      .catch((err) => console.error(err.message));*/
    client.getNotifications()
      .then((resp) => setNotifications(resp.data))
      .catch((err) => console.error(err.message));
  }, []);

  /*const toggleProject = (index, checked) => {
    projects[index].checked = !projects[index].checked;
    setProjects([...projects]);
  };*/

  return (
    <header className="header">
      <img className="logo" src={Logo} alt="Titan4 Logo" />
      <div style={{ display: "flex" }}>
        {/*<Dropdown title="Switch Project" inverted={true}>
          <div className="switch-activity-dropdown">
            {projects.map((project, index) => (
              <div className="switch-activity-item" key={index}>
                <div>
                  <div className="activity-title">{project.name}</div>
                  <div className="activity-date">{project.createDate}</div>
                </div>
                <Switch
                  backgroundColor={{ on: "#f0941e", off: "#2F3B57" }}
                  borderColor={{ on: "transparent", off: "transparent" }}
                  onChange={(e) => toggleProject(index, e)}
                  value={project.checked}
                />
              </div>
            ))}
          </div>
        </Dropdown>*/}
        <Dropdown title="Notificatons" inverted={true}>
          <div className="notification-dropdown">
            {notifications.map(({ title, dateTime }, index) => (
                <div className="notification-item" key={index}>
                  <div>
                    <div className="notification-title">{title}</div>
                    <div className="notification-date">{dateTime}</div>
                  </div>
                  <i className="icon-external-link"></i>
                </div>
              ))}
          </div>
        </Dropdown>
        <Dropdown title="Settings" inverted={true}>
          <div className="setting-dropdown">
            {/*<div>Mode</div>
            <label>
              <input type="radio" name="mode" defaultChecked /> Light
            </label>
            <label>
              <input type="radio" name="mode" /> Dark
            </label>
            <label>
              <input type="radio" name="mode" /> Default
            </label>
            <hr />
            <div>Units</div>
            <label>
              <input type="radio" name="units" defaultChecked /> km²
            </label>
            <label>
              <input type="radio" name="units" /> mi²
            </label>
            <hr />*/}
            <div>TimeSeries Viewer</div>
            <label>
              <input type="radio" name="viewer" defaultChecked={settings.tsView === 'basic'} onChange={() => setSettings({ ...settings, tsView: 'basic' }) }/> Basic
            </label>
            <label>
              <input type="radio" name="viewer" defaultChecked={settings.tsView === 'advanced'} onChange={() => setSettings({ ...settings, tsView: 'advanced' }) } /> Advanced
            </label>
          </div>
        </Dropdown>
        <Dropdown title={<img src={User} alt="User" />} hideArrow={true}>
          <div className="profile-dropdown">
            <img src={User} alt="User" />
            <h6>{user.name}</h6>
            <small>{user.email}</small>
            <button style={{
              display: 'block',
              borderColor: 'white',
              margin: '20px auto 5px',
            }} onClick={() => navigate('/profile')}>
              Manage your account
            </button>
            <div className="sign-out cursor" style={{ justifyContent: 'center' }} onClick={() => logout()}>
              <i className='icon-sign-out'></i>
              <div style={{ marginLeft: '5px' }}> Sign Out</div>
            </div>
          </div>
        </Dropdown>
      </div>
    </header>
  );
}

export default Header;
