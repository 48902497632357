import React from "react";
import "./scss/styles.scss";
import "./icons/icons.css";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './pages/PrivateRoute';
import Home from './pages/Home';
import NewRequest from './pages/NewRequest';
import RequestsList from './pages/RequestsList';
import RequestsDetail from './pages/RequestsDetail';
import Monitoring from './pages/Monitoring';
import Profile from './pages/Profile';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Home />} />
            <Route path="/new-request" element={<NewRequest />} />
            <Route path="/requests" element={<RequestsList />} />
            <Route path="/requests/:requestId" element={<RequestsDetail />} />
            <Route path="/monitoring" element={<Monitoring />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
