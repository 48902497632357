import React, { useState, useRef, useContext, useEffect } from "react";
import AuthContext from '../context/AuthContext';
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

function Monitoring() {
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem(process.env.REACT_APP_COOKIE_NAME);
  const { settings } = useContext(AuthContext);
  const iframeSrc = `${process.env.REACT_APP_GIS_API}?token=${token}`;
  const iframeRef = useRef(null);

  useEffect(() => {
    sendMessageToChild({ type: 'settings', value: settings });
  }, [settings]);

  const sendMessageToChild = (message) => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.postMessage(message, '*');
    }
  };

  return (
    <div className="requests">
      <Header />
      <div className="main-wrapper">
        <Sidebar />
        <div className="main-content">
          {isLoading && (
            <div className="loader-wrapper">
              <div className="loader"></div>
              <p style={{ margin: '20px 10px'}}>Loading GIS interface...</p>
            </div>
          )}
          <iframe
            ref={iframeRef}
            title="Titan4 GIS" 
            style={{ border: 0, width: '100%', height: '100%', display: isLoading ? 'none' : 'block' }}
            src={iframeSrc}
            onLoad={() => { 
              setIsLoading(false);
              sendMessageToChild({ type: 'settings', value: settings });
            }}
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Monitoring;
