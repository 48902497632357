import React, { useRef, useState } from "react";
import Map from "react-map-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import Modal from "./Modal";
import { Autocomplete, TextField, Box } from "@mui/material";

const MAPBOX_TOKEN = "pk.eyJ1IjoiZnJ4MDgiLCJhIjoiY2puOGtrczNnNmh4cDNrbngwaXQ1Nm42NyJ9.TTsHdLQig2CLRi9wtRviRg";

function ModalDrawMap({ isOpen, onClose, onDone }) {
  const mapRef = useRef(null);
  const drawRef = useRef(null);
  const [searchResults, setSearchResults] = useState([]);
  const debounceTimer = useRef(null);

  const handleMapLoad = (event) => {
    const map = event.target;

    if (!drawRef.current) {
      drawRef.current = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true,
        },
      });
      if (!map.hasControl(drawRef.current)) {
        map.addControl(drawRef.current, "top-right");
        drawRef.current.changeMode("draw_polygon");
      }
    }
  };

  const handleClose = () => {
    const map = mapRef.current?.getMap();
    if (map && drawRef.current) {
      try { map.removeControl(drawRef.current); } catch(e) {}
      drawRef.current = null;
    }
    onClose();
  };

  const handleDone = () => {
    if (drawRef.current) {
      try {
        const data = drawRef.current?.getAll();
        if (data?.features.length > 0) {
          onDone(data);
        }
      } catch (e) {
        console.error("Error getting draw data:", e);
      }
    }
    handleClose();
  };

  const fetchSearchResults = async (term) => {
    if (!term) {
      setSearchResults([]);
      return;
    }
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search.php?q=${encodeURIComponent(term)}&polygon_geojson=1&format=json&limit=5`
      );
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const handleSearchChange = (event, value) => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = setTimeout(() => {
      fetchSearchResults(value);
    }, 500);
  };

  const handleSelectLocation = (event, selectedOption) => {
    if (selectedOption && selectedOption.boundingbox) {
      const map = mapRef.current?.getMap();
      if (map) {
        const bbox = selectedOption.boundingbox.map(Number);
        map.fitBounds(
          [
            [bbox[2], bbox[0]],
            [bbox[3], bbox[1]],
          ],
          {
            padding: 20,
            duration: 1000,
          }
        );
      }
    }
  };

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} className="large">
      <Box style={{ position: "relative", marginBottom: "10px" }}>
        <Autocomplete
          freeSolo
          options={searchResults}
          getOptionLabel={(option) => option.display_name || ""}
          onInputChange={handleSearchChange}
          onChange={handleSelectLocation}
          filterOptions={(options) => options}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Location"
              variant="outlined"
              fullWidth
              placeholder="Type a location..."
            />
          )}
        />
      </Box>

      <div className="modal-time-content" style={{ height: "400px" }}>
        <Map
          ref={mapRef}
          initialViewState={{
            latitude: 41.8719,
            longitude: 12.56,
            zoom: 5,
          }}
          mapboxAccessToken={MAPBOX_TOKEN}
          mapStyle="mapbox://styles/mapbox/streets-v11"
          style={{ width: "100%", height: "100%" }}
          onLoad={handleMapLoad}
        />
      </div>

      <div className="modal-actions" style={{ justifyContent: 'flex-end' }}>
        <button className="button-outline outline" onClick={handleClose}>
          Cancel
        </button>
        <button className="button-dark" onClick={handleDone}>
          Done
        </button>
      </div>
    </Modal>
  );
}

export default ModalDrawMap;
