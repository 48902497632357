import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../context/AuthContext";
import { client } from "../services/api";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Requests from "../components/Requests";
import ContentWrapper from "../components/ContentWrapper";

function RequestsList() {
  const { user } = useContext(AuthContext);
  const [requests, setRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("all");

  useEffect(() => {
    client.getRequests()
      .then((resp) => setRequests(resp.data))
      .catch((err) => console.error(err.message));
  }, []);

  useEffect(() => {
    const orgs = Array.from(new Set(requests.map((r) => r.organization.name)));
    setOrganizations(orgs);
    setFilteredRequests(requests);
  }, [requests]);

  const handleFilterChange = (event) => {
    const org = event.target.value;
    setSelectedOrg(org);
    if (org === "all") {
      setFilteredRequests(requests);
    } else {
      setFilteredRequests(requests.filter((r) => r.organization.name === org));
    }
  };

  return (
    <div className="requests">
      <Header />
      <div className="main-wrapper">
        <Sidebar />
        <div className="main-content">
          <div className="main-content-primary" style={{ padding: '36px 24px' }}>
            <h5>Requests</h5>
            {user.admin && (
              <div style={{ marginBottom: "20px" }}>
                <label htmlFor="organizationFilter" style={{ marginRight: "10px" }}>
                  Filter by Organization:
                </label>
                <select
                  id="organizationFilter"
                  value={selectedOrg}
                  onChange={handleFilterChange}
                >
                  <option value="all">All</option>
                  {organizations.map((org) => (
                    <option key={org} value={org}>
                      {org}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <ContentWrapper>
              <Requests requests={filteredRequests} />
            </ContentWrapper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestsList;
