import React, { useState } from "react";
import ModalAddDataFile from "../ModalAddDataFile";
import ModalAddDataURL from "../ModalAddDataURL";
import { defaultOpenDataSources } from "../../constants/formOptions";

function StepExistingData({ formData, updateFormData }) {
  const [isAddDataFileModalOpen, setIsAddDataFileModalOpen] = useState(false);
  const [isAddDataURLModalOpen, setIsAddDataURLModalOpen] = useState(false);

  const addDataFiles = (dataFile) => {
    updateFormData("dataFiles", [...formData.dataFiles, dataFile]);
  };

  const removeDataFiles = (id) => {
    updateFormData("dataFiles", formData.dataFiles.filter((item) => item._id !== id));
  };

  const addDataURL = (dataURL) => {
    updateFormData("dataURLs", [...formData.dataURLs, dataURL]);
  };

  const removeDataURL = (id) => {
    updateFormData("dataURLs", formData.dataURLs.filter((item) => item._id !== id));
  };

  const [newSource, setNewSource] = useState("");

  const handleOpenDataSourceChange = (source) => {
    const updatedSources = formData.openDataSources.includes(source)
      ? formData.openDataSources.filter((item) => item !== source)
      : [...formData.openDataSources, source];

    updateFormData("openDataSources", updatedSources);
  };

  const handleAddNewSource = () => {
    if (newSource.trim() && !formData.openDataSources.includes(newSource)) {
      updateFormData("openDataSources", [...formData.openDataSources, newSource]);
      setNewSource("");
    }
  };

  return (
    <div>
      <h3>Existing Data</h3>
      <div className="bubble" >
        <p>Are there existing data for the area?</p>
        <label>
          <input
            type="radio"
            name="existingData"
            value="yes"
            checked={formData.existingData === "yes"}
            onChange={() => updateFormData("existingData", "yes")}
          />
          Yes
        </label>
        <label>
          <input
            type="radio"
            name="existingData"
            value="no"
            checked={formData.existingData === "no"}
            onChange={() => updateFormData("existingData", "no")}
          />
          No
        </label>
        {formData.existingData === "yes" && (
          <>
            <ul className="item-list">
              {formData.dataFiles.map((item, index) => (
                <li key={index}><i className='icon-remove' onClick={() => removeDataFiles(item._id)}></i> {item.name} <span>{item.type}</span></li>
              ))}
            </ul>
            <button className="action-link" onClick={() => setIsAddDataFileModalOpen(true)}>+ Add a data file</button>
            <ModalAddDataFile projectId={formData.project} isOpen={isAddDataFileModalOpen} onClose={() => setIsAddDataFileModalOpen(false)} onDone={addDataFiles} />
          </>
        )}
      </div>

      <div className="bubble">
        <div style={{ marginTop: "20px" }}>
          <p>Are you interested in integrating with Open Data and other public sources?</p>
          <label>
            <input
              type="radio"
              name="openDataIntegration"
              value="yes"
              checked={formData.openDataIntegration === "yes"}
              onChange={() => updateFormData("openDataIntegration", "yes")}
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              name="openDataIntegration"
              value="no"
              checked={formData.openDataIntegration === "no"}
              onChange={() => updateFormData("openDataIntegration", "no")}
            />
            No
          </label>
        </div>

        {formData.openDataIntegration === "yes" && (
          <div style={{ marginTop: "20px" }}>
            <p>Select Open Data Sources:</p>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", flexDirection: "column" }}>
              {defaultOpenDataSources.map((source) => (
                <label key={source} style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    checked={!["Pluviometri","MOISTURE INDEX","NDWI","NDVI","SWIR","False-color-urban",].includes(source)/*formData.openDataSources.includes(source)*/}
                    disabled={true}
                    onChange={() => handleOpenDataSourceChange(source)}
                    style={{ marginRight: "10px" }}
                  />
                  {source}
                </label>
              ))}
              {formData.openDataSources
                .filter((source) => !defaultOpenDataSources.includes(source))
                .map((source) => (
                  <label key={source} style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      checked={formData.openDataSources.includes(source)}
                      onChange={() => handleOpenDataSourceChange(source)}
                      style={{ marginRight: "10px" }}
                    />
                    {source}
                  </label>
                ))}
            </div>

            <div style={{ marginTop: "20px", display: "flex", gap: "10px" }}>
              <input
                type="text"
                value={newSource}
                onChange={(e) => setNewSource(e.target.value)}
                placeholder="Add a new source"
              />
              <button type="button" onClick={handleAddNewSource} style={{ padding: "5px 10px" }}>
                +
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="bubble">
        <div style={{ marginTop: "20px" }}>
          <p>Are you interested in selecting files from third-party databases?</p>
          <label>
            <input
              type="radio"
              name="thirdPartyFiles"
              value="yes"
              checked={formData.thirdPartyFiles === "yes"}
              onChange={() => updateFormData("thirdPartyFiles", "yes")}
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              name="thirdPartyFiles"
              value="no"
              checked={formData.thirdPartyFiles === "no"}
              onChange={() => updateFormData("thirdPartyFiles", "no")}
            />
            No
          </label>
          {formData.thirdPartyFiles === "yes" && (
            <>
              <ul className="item-list">
                {formData.dataURLs.map((item, index) => (
                  <li key={index}><i className='icon-remove' onClick={() => removeDataURL(item._id)}></i> {item.name} <span>{item.type}</span></li>
                ))}
              </ul>
              <button className="action-link" onClick={() => setIsAddDataURLModalOpen(true)}>+ Add a data URL</button>
              <ModalAddDataURL isOpen={isAddDataURLModalOpen} onClose={() => setIsAddDataURLModalOpen(false)} onDone={addDataURL} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default StepExistingData;
