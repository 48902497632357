import React from 'react';
import SidebarButton from './SidebarButton';
import { ReactComponent as Home } from '../images/home.svg';
import { ReactComponent as DataCollection } from '../images/data-collection.svg';
import { ReactComponent as AINetwork } from '../images/ai-network.svg';
import { ReactComponent as Climate } from '../images/climate.svg';
import { ReactComponent as Alert } from '../images/alert.svg';
import { ReactComponent as Satellite } from '../images/satellite.svg';
import { ReactComponent as Simulator } from '../images/simulator.svg';
import { ReactComponent as Dashboard } from '../images/dashboard.svg';
import { ReactComponent as Reporting } from '../images/reporting.svg';
import { ReactComponent as Integration } from '../images/integration.svg';

function Sidebar() {
  return (
    <div className="sidebar">
      <SidebarButton title="Home" icon={<Home />} to="/" />
      <SidebarButton title="Assestment &amp; Monitoring" icon={<Satellite />} to="/monitoring" />
      <SidebarButton title="Data Source" icon={<DataCollection />} disabled={true} />
      <SidebarButton title="AI Model" icon={<AINetwork />} disabled={true} />
      <SidebarButton title="Climate Engine" icon={<Climate />} disabled={true} />
      <SidebarButton title="Alarms Engine" icon={<Alert />} disabled={true} />
      <SidebarButton title="Simultaor" icon={<Simulator />} disabled={true} />
      <SidebarButton title="Dashboard" icon={<Dashboard />} disabled={true} />
      <SidebarButton title="Reporting" icon={<Reporting />} disabled={true} />
      <SidebarButton title="Third Party Integration" icon={<Integration />} disabled={true} />
    </div>
  );
}

export default Sidebar;