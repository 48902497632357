import React, { useEffect, useState } from "react";
import { client } from "../services/api";
import { ObjectId } from 'bson';
import { toast } from "react-toastify";
import Modal from "./Modal";
import { LinearProgress, Box, Typography } from "@mui/material";
import { structureTypes, structuralIssues } from "../constants/formOptions";

function ModalAddStrucure({ isOpen, onClose, onDone, projectId, structure }) {
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [issues, setIssues] = useState([]);
  const [dataFile, setDataFile] = useState(null);
  const [existingDataFile, setExistingDataFile] = useState(null);
  const [existingImageIssueFile, setExistingImageIssueFile] = useState(null);
  const [imageIssueFile, setImageIssueFile] = useState(null);
  const [description, setDescription] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadProgressDescription, setUploadProgressDescription] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const handleClose = () => {
    if (isUploading) return;
    if (dataFile) {
      if (window.confirm("The structure file uploaded will be deleted, continue?")) {
        client.deleteGeometry(projectId, dataFile)
          .then(() => toast.success("Structure file deleted successfully!"));
      } else {
        return;
      }
    }
    resetForm();
    onClose();
  };

  const handleDone = () => {
    if (!name.trim() || !type) {
      toast.error("Please provide both name and type for the structure.", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    onDone({ _id: new ObjectId(), name, type, issues, dataFile, imageIssueFile, description });
    if (isUploading) return;
    resetForm();
    onClose();
  };

  const handleCheckboxChange = (value) => {
    setIssues((prevIssues) =>
      prevIssues.includes(value)
        ? prevIssues.filter((issue) => issue !== value)
        : [...prevIssues, value]
    );
  };

  useEffect(() => {
    if (structure) {
      setType(structure.type);
      setIssues(structure.issues);
      client.getDataFile(structure.dataFile).then(result => {
        if (result.statusText === 'OK') {
          setExistingDataFile(result.data);
        }
      });
      client.getUpload(structure.imageIssueFile).then(result => {
        if (result.statusText === 'OK') {
          setExistingImageIssueFile(result.data);
        }
      });
    }
  }, [structure]);

  const resetForm = () => {
    setName("");
    setType("");
    setIssues([]);
    setDataFile(null);
    setImageIssueFile(null);
    setDescription("");
    setUploadProgress(0);
    setUploadProgressDescription("");
    setIsUploading(false);
  };

  const handleFileUpload = async (file, setFileState) => {
    if (!file) {
      toast.error("Please select a file to upload.");
      return;
    }
    if (!projectId) {
      toast.error("Please select a project to upload.");
      return;
    }

    const MAX_FILE_SIZE = 5 * 1024 * 1024;
    if (file.size > MAX_FILE_SIZE) {
      toast.error("File size exceeds 5MB. Please upload a smaller file.", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    const fileName = file.name.replace(/\.[^/.]+$/, "").replace(/_/g, " ");

    setIsUploading(true);
    setUploadProgress(0);
    setUploadProgressDescription("");

    try {
      let currentStep = 0;
      let totalSteps = 1;
      client.uploadGeometry(file, fileName, projectId, (progress) => {
        if (progress.status === "started") {
          totalSteps = progress.steps;
        } else if (progress.status === "progress") {
          currentStep++;
          setUploadProgress((currentStep / totalSteps) * 100);
          setUploadProgressDescription(progress.description);
        } else if (progress.status === "done") {
          setFileState(progress.result.datafile_id);
          toast.success("File uploaded successfully!");
          setUploadProgress(100);
          setUploadProgressDescription("");
          setIsUploading(false);
        } else if (progress.status === "error") {
          toast.error("File upload error: " + progress.message);
          setUploadProgress(0);
          setUploadProgressDescription("");
          setIsUploading(false);
        }
      });
    } catch (error) {
      console.error("File upload error:", error);
      toast.error("File upload failed.");
      setIsUploading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} className="large">
      <div className="modal-add-structure">
        <h6>Add a structure</h6>
        Structure Name:<br />
        <input type="text" defaultValue={structure?.name} onChange={(e) => setName(e.target.value)} disabled={!!structure} />
        <br />
        {!!structure ? (
          <>
            Structure file: {existingDataFile?.upload && <a href={`${process.env.REACT_APP_URL_API}/upload/${existingDataFile?.upload._id}/download`} target="_blank" rel="noreferrer">{existingDataFile?.upload.filename}</a>}
          </>
        ) : (
          <>
            Select the structure file (.geojson, .kml, .kmz, Zipped Shapefile .zip):<br />
            <input
              type="file"
              onChange={(e) => handleFileUpload(e.target.files[0], setDataFile)}
              accept=".geojson,.GEOJSON,.kml,.KML,.kmz,.KMZ,.zip,.ZIP"
            />
            {isUploading && (
              <Box sx={{ width: "100%", mt: 2 }}>
                <LinearProgress variant="determinate" value={uploadProgress} />
                <Typography variant="body2" color="text.secondary" align="center">
                  {`${Math.round(uploadProgress)}%`} {uploadProgressDescription && `- ${uploadProgressDescription}`}
                </Typography>
              </Box>
            )}
          </>
        )}
        <br />
        Select the type of structure:
        <div className="radio-group">
          {structureTypes.map((option) => (
            <label key={option.value}>
              <input
                type="radio"
                name="structure_type"
                value={option.value}
                checked={type === option.value}
                onChange={(e) => setType(e.target.value)}
                disabled={!!structure}
              />
              {option.label}
            </label>
          ))}
        </div>
        <textarea
          placeholder="Description (less than 100 words)"
          className="description"
          onChange={(e) => setDescription(e.target.value)}
          style={{ width: '100%' }}
          defaultValue={structure?.description}
          disabled={!!structure}
        /><br/>
        Define if there is a structural issue:
        <div className="radio-group">
          {structuralIssues.map((option) => (
            <label key={option.value}>
              <input
                type="checkbox"
                name="structural_issues[]"
                value={option.value}
                checked={issues.includes(option.value)}
                onChange={() => handleCheckboxChange(option.value)}
                disabled={!!structure}
              />
              {option.label}
            </label>
          ))}
        </div>
        {!!structure ? (
          <>
            Image issue: {existingImageIssueFile && <a href={`${process.env.REACT_APP_URL_API}/upload/${existingImageIssueFile._id}/download`} target="_blank" rel="noreferrer">{existingImageIssueFile.filename}</a>}
          </>
        ) : (
          <>
            Upload the images of the issue:<br />
            <input
              type="file"
              className="image-issue-file"
              style={{ width: "auto", height: "auto" }}
              onChange={(e) => setImageIssueFile(e.target.files[0])}
            />
          </>
        )}
      </div>

      <div className="modal-actions" style={{ justifyContent: "flex-end" }}>
      {!!structure ? (
          <>
            <button className="button-outline outline" onClick={handleClose}>
              Close
            </button>
          </>
        ) : (
          <>
            <button className="button-outline outline" onClick={handleClose} disabled={isUploading}>
              Cancel
            </button>
            <button className="button-dark" onClick={handleDone} disabled={isUploading}>
              Done
            </button>
          </>
        )}
      </div>
    </Modal>
  );
}

export default ModalAddStrucure;
