import React, { useContext } from 'react';
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import ContentWrapper from "../components/ContentWrapper";
import AuthContext from '../context/AuthContext';
import User from '../images/user.png';

function Profile() {
  const { logout, user } = useContext(AuthContext);
  return (
    <div className="progile">
      <Header />
      <div className="main-wrapper">
        <Sidebar />
        <div className="main-content">
          <div className="main-content-primary" style={{ padding: '36px 24px' }}>
            <ContentWrapper>
              <div className="row">
                <div className="col-4 section-bubble" style={{ textAlign: 'center' }}>
                  <img src={User} alt="User" />
                  <h6>{user.name}</h6>
                  <table>
                    <tr>
                      <th>Email</th>
                      <td>{user.email}</td>
                    </tr>
                    <tr>
                      <th>Phone</th>
                      <td>-</td>
                    </tr>
                    <tr>
                      <th>Company</th>
                      <td>{user.organization}</td>
                    </tr>
                    <tr>
                      <th>Position</th>
                      <td>-</td>
                    </tr>
                    <tr>
                      <th>Country</th>
                      <td>-</td>
                    </tr>
                  </table>
                  <span className="sign-out cursor" onClick={() => logout()}>
                    <i className='icon-sign-out'></i>
                    <div>Sign Out</div>
                  </span>
                </div>
                <div style={{ width: '75%', padding: '0 24px' }}>
                  <h5>Your Teams</h5>
                </div>
              </div>
            </ContentWrapper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
