import React from "react";
import Modal from "./Modal";
import { geohazards, getLabelByValue } from "../constants/formOptions";

function ModalIssues({ isOpen, onClose, issues }) {

  const handleClose = () => {
    onClose();
  };

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} className="large">
      <div className="modal-add-data-file">
        <h6>Existing Issues</h6>

        <ul>
          {issues && Object.entries(issues).map(([k, v]) => (
            <li key={k}>
              {getLabelByValue(geohazards, k)}: 
              <ul>
                {Object.entries(v).map(([k2, v2]) => (
                  <li key={k2}>{k2}: {v2.value}</li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
        
      </div>
      <div className="modal-actions" style={{ justifyContent: 'flex-end' }}>
        <button className="button-outline outline" onClick={handleClose}>
          Close
        </button>
      </div>
    </Modal>
  );
}

export default ModalIssues;
