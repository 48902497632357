import React, { useEffect, useState, useRef, useCallback, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import Map, { Source, Layer } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import wkt from "wellknown";
import bbox from "@turf/bbox";
import center from "@turf/center";
import area from "@turf/area";
import { useParams } from "react-router-dom";
import { client } from "../services/api";
import { toast } from "react-toastify";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { LinearProgressWithLabel } from "../components/LinearProgressWithLabel";
import { getLabelByValue, structureTypes, dataTypes, mainObjectives, geohazards } from "../constants/formOptions";
import LoadingModal from "../components/LoadingModal";
import ModalRequestAssessment from "../components/ModalRequestAssessment";
import ModalAddStrucure from "../components/ModalAddStrucure";
import ModalIssues from "../components/ModalIssues";
import ModalAddDataFile from "../components/ModalAddDataFile";
import ModalAddDataURL from "../components/ModalAddDataURL";

function RequestsDetail() {
  const { requestId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const [request, setRequest] = useState({});
  const [geometryInfo, setGeometryInfo] = useState({ center: null, area: null });
  const mapRef = useRef();
  const [rejectionReason, setRejectionReason] = useState('');
  const [selectedStructure, setSelectedStructure] = useState(null);
  const [selectedDataFile, setSelectedDataFile] = useState(null);
  const [selectedDataURL, setSelectedDataURL] = useState(null);

  // modals
  const [isAssessmentModalOpen, setIsAssessmentModalOpen] = useState(false);
  const [isStructureModalOpen, setStructureModalOpen] = useState(false);
  const [isIssuesModalOpen, setIssuesModalOpen] = useState(false);
  const [isDataFileModalOpen, setDataFileModalOpen] = useState(false);
  const [isDataURLModalOpen, setDataURLModalOpen] = useState(false);

  const [viewport, setViewport] = useState({
    latitude: 41.8719,
    longitude: 12.56,
    zoom: 10,
  });

  const geoJsonLayer = {
    id: 'geojson-layer',
    type: 'fill',
    paint: {
      'fill-color': '#010E2D',
      'fill-opacity': 0.5,
    },
  };

  const fitGeometry = useCallback(() => {
    if (request.form?.areaOfInterest && mapRef.current) {
      const bounds = bbox(request.form?.areaOfInterest);

      mapRef.current.fitBounds(bounds, { padding: 20 });
    }
  }, [request.form?.areaOfInterest]);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.resize();
    }
    fitGeometry();
  }, [request.form?.areaOfInterest, fitGeometry]);

  useEffect(() => {
    client.getRequestById(requestId)
      .then((resp) => setRequest(resp.data))
      .catch((err) => console.error(err.message));
  }, [requestId]);

  useEffect(() => {
    if (request.form) {
      const geoCenter = center(request.form.areaOfInterest);
      const [lng, lat] = geoCenter.geometry.coordinates;

      const geoArea = area(request.form.areaOfInterest) / 1_000_000;

      setGeometryInfo({ center: { lat, lng }, area: geoArea.toFixed(2) });
    }
  }, [request]);

  const removeStructure = async (structure) => {
    if (window.confirm("The structure will be deleted, continue?")) {
      setLoading(true);
      const result = await client.deleteRequestStructure(request._id, structure._id);
      if (result.statusText === 'OK') {
        toast.success("Structure file deleted successfully!");

        const updatedStructures = request.form?.structures.filter((s) => s._id !== structure._id);
        setRequest((prevRequest) => ({
          ...prevRequest,
          form: {
            ...prevRequest.form,
            structures: updatedStructures,
          },
        }));
      } else {
        toast.error("Structure file deletion failed!");
      }
      setLoading(false);
    }
  };

  const removeDataFile = async (dataFile) => {
    if (window.confirm("The dataFile will be deleted, continue?")) {
      setLoading(true);
      const result = await client.deleteRequestDataFile(request._id, dataFile._id);
      
      if (result.statusText === 'OK') {
        toast.success("DataFile deleted successfully!");

        const updatedDataFiles = request.form?.dataFiles.filter((d) => d._id !== dataFile._id);
        setRequest((prevRequest) => ({
          ...prevRequest,
          form: {
            ...prevRequest.form,
            dataFiles: updatedDataFiles,
          },
        }));
      } else {
        toast.error("DataFile deletion failed!");
      }
      setLoading(false);
    }
  }

  const removeDataURL = async (dataURL) => {
    if (window.confirm("The dataURL will be deleted, continue?")) {
      setLoading(true);
      const result = await client.deleteRequestDataURL(request._id, dataURL._id);
      if (result.statusText === 'OK') {
        toast.success("DataURL deleted successfully!");

        const updatedDataURLs = request.form?.dataURLs.filter((d) => d._id !== dataURL._id);
        setRequest((prevRequest) => ({
          ...prevRequest,
          form: {
            ...prevRequest.form,
            dataURLs: updatedDataURLs,
          },
        }));
      } else {
        toast.error("DataURL deletion failed!");
      }
      setLoading(false);
    }
  }

  const deleteRequest = async () => {
    if (window.confirm("The request will be deleted, continue?")) {
      setLoading(true);
      const result = await client.deleteRequest(request._id);
      if (result.statusText === 'OK') {
        navigate('/')
      } else {
        toast.error("Request deletion failed!");
      }
      setLoading(false);
    }
  }

  const rejectAction = async (action) => {
    const updatedActions = request.actions.map((a) =>
      a._id === action._id ? { ...a, status: 'rejected' } : a
    );
    setRequest((prevRequest) => ({
      ...prevRequest,
      actions: updatedActions,
    }));
  }

  const approveAction = async (action) => {
    setLoading(true);
    const response = await client.updateRequestAction(request._id, action._id, {
      status: "approved",
    });
    if (response.status === 200) {
      const updatedActions = request.actions.map((a) =>
        a._id === action._id ? { ...a, status: 'approved' } : a
      );
      setRequest((prevRequest) => ({
        ...prevRequest,
        actions: updatedActions,
      }));
    } else {
      toast.error("Failed to approve the action.");
    }
    setLoading(false);
  }

  const revertRejectAction = async (action) => {
    const updatedActions = request.actions.map((a) =>
      a._id === action._id ? { ...a, status: 'waiting' } : a
    );
    setRequest((prevRequest) => ({
      ...prevRequest,
      actions: updatedActions,
    }));
  }

  const sendRejectAction = async (action) => {
    if(rejectionReason.trim()) {
      setLoading(true);
      const response = await client.updateRequestAction(request._id, action._id, {
        status: "rejected",
        rejectionReason,
      });
      if (response.status === 200) {
        const updatedActions = request.actions.map((a) =>
          a._id === action._id ? { ...a, rejectionReason } : a
        );
        setRequest((prevRequest) => ({
          ...prevRequest,
          actions: updatedActions,
        }));
      } else {
        toast.error("Failed to reject the action.");
      }
    } else {
      toast.error("Please provide a rejection reason.");
    }
    setLoading(false);
  }

  const handleSubmitAssessment = async (action) => {
    setLoading(true);
    const response = await client.addRequestAction(request._id, action);
    if (response.status === 201) {
      setRequest((prevRequest) => ({
        ...prevRequest,
        actions: response.data.actions,
      }));
    }
    setLoading(false);
  };

  return (
    <div className="request-details">
      <Header />
      <div className="main-wrapper">
        <Sidebar />
        <div className="main-content">
          <LoadingModal isOpen={!!isLoading} />
          <ModalRequestAssessment isOpen={isAssessmentModalOpen} onClose={() => setIsAssessmentModalOpen(false)} onDone={handleSubmitAssessment} />
          <ModalAddStrucure isOpen={isStructureModalOpen} onClose={() => setStructureModalOpen(false)} structure={selectedStructure} />
          <ModalIssues isOpen={isIssuesModalOpen} onClose={() => setIssuesModalOpen(false)} issues={request?.form?.geohazards} />
          <ModalAddDataFile  isOpen={isDataFileModalOpen} onClose={() => setDataFileModalOpen(false)} dataFile={selectedDataFile} />
          <ModalAddDataURL  isOpen={isDataURLModalOpen} onClose={() => setDataURLModalOpen(false)} dataURL={selectedDataURL} />
          <div className="main-content-primary" style={{ padding: '36px 24px' }}>
            <h5>Request ID: {request._id}</h5>
            <a href={`${process.env.REACT_APP_URL_API}/request/report/${request._id}`} target="_blank" rel="noreferrer">Report</a>
            <h6>Creation date: {new Date(request.createDate).toLocaleDateString()}</h6>
            <h6>Created by: {request.user?.name}</h6>
            <div style={{ display: "flex" }}>
              <div className="content-wrapper" style={{ flex: 50 }}>
                <span>Project: {request.project?.name}</span>
                <span>Request Status: {request.status}</span>
              </div>
              <div className="content-wrapper" style={{ flex: 50 }}>
                <span>Activity Scope: {request.form?.requestType?.join(", ")}</span>
                <LinearProgressWithLabel value={Number(request.progress) || 10} />
              </div>
            </div>
            {request.actions && (
              <>
                <h6 style={{ color: '#FF9000' }}>Actions required</h6>
                {request.actions.map((action, index) => (
                  <div key={index} className={`content-wrapper action-box ${action.status}`}>
                    
                    <div style={{ display: "flex" }}>
                      <div>
                        <p>
                          <span style={{ flex: 50, marginRight: '5px' }} dangerouslySetInnerHTML={{ __html: action.description }} />
                          {action.status === 'waiting' && 'Please review and approve this selection to proceed.'}
                          {action.status === 'approved' && <span style={{ color: '#00BA29' }}>Approved</span>}
                          {action.status === 'rejected' && <span style={{ color: '#E10101' }}>Rejected</span>}
                        </p>
                        {action.status === 'rejected' && !action.rejectionReason && (
                          <>
                            <textarea
                              placeholder="Tell us why you rejected..."
                              style={{ width: '100%'}}
                              value={rejectionReason}
                              onChange={(e) => setRejectionReason(e.target.value)}
                            />
                            <button onClick={() => revertRejectAction(action)} className="outline">Cancel</button>
                            <button onClick={() => sendRejectAction(action)}>Submit rejection</button>
                          </>
                        )}
                        {action.status === 'rejected' && action.rejectionReason && (
                          <p>Reason: <i>{action.rejectionReason}</i></p>
                        )}
                      </div>
                      {action.status === 'waiting' && (
                        <div style={{ flex: 50, display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                          <button onClick={() => rejectAction(action)} className="outline" style={{ width: '160px', margin: '0 24px' }}>Reject</button>
                          <button onClick={() => approveAction(action)} style={{ width: '160px' }}>Process</button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
            <h6>Request details</h6>
            <div className="content-wrapper">
              <div style={{ display: "flex", marginBottom: '10px' }}>
                <div style={{ flex: 50 }}>
                  <h6>Area of Interest</h6>
                  <span>Area: {geometryInfo.area && `${geometryInfo.area} km²`}</span><br/>
                  <span>Geographical Coordinates: { geometryInfo.center?.lat && `${geometryInfo.center?.lat?.toFixed(5)}, ${geometryInfo.center?.lng?.toFixed(5)}`}</span>
                </div>
                <div style={{ flex: 50 }}>
                  <h6>Time of Interest</h6>
                  {request.form?.requestActivityAssessment && (
                    <>
                      <span>Type: Assessment</span><br/>
                      <span>Duration: from {`${request.form?.timeOfInterestAssessment?.from || "Not specified"} to ${request.form?.timeOfInterestAssessment?.to || "Not specified"}`}</span><br/>
                    </>
                  )}
                  {request.form?.requestActivityMonitoring && (
                    <>
                      <span>Type: Monitoring</span><br/>
                      <span>Duration: from {`${request.form?.timeOfInterest?.from || "Not specified"} to ${request.form?.timeOfInterest?.to || "Not specified"}`}</span><br/>
                    </>
                  )}
                </div>
              </div>
              <Map
                ref={mapRef}
                style={{ width: '500px', height: '250px' }}
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                initialViewState={viewport}
                mapStyle="mapbox://styles/mapbox/streets-v11"
                onMove={(evt) => setViewport(evt.viewState)}
              >
                {request.form?.areaOfInterest && (
                  <Source id="geojson-source" type="geojson" data={request.form?.areaOfInterest}>
                    <Layer {...geoJsonLayer} />
                  </Source>
                )}
              </Map>
            </div>
            <div className="content-wrapper">
              <h6>Main objective</h6>
              <span>{request.form?.selectedObjectives.map((i) => getLabelByValue(mainObjectives, i)).join(', ')}</span>
              <ul className="item-list">
                {request.form?.structures.map((item, index) => {
                  const extentGeoJson = item.layer?.extent ? wkt.parse(item.layer?.extent) : null;
                  const bounds = extentGeoJson ? bbox(extentGeoJson) : null;
                  return (<li key={index} style={{ border: '1px solid #000', borderBottom: '5px' }}>
                    <div className="row">
                      <div className="col-2" style={{ display: 'flex', justifyContent: 'space-between', padding: '0 10px' }}>
                        <div>
                          <i className='icon-remove' style={{ cursor: "pointer" }} onClick={() => removeStructure(item)}></i>
                          <span style={{ cursor: "pointer" }} onClick={() => { setSelectedStructure(item); setStructureModalOpen(true); }}>{item.name}</span>
                        </div>
                        <span style={{ opacity: 0.5 }}>{getLabelByValue(structureTypes, item.type)}</span>
                      </div>
                      <div className="col-2">
                        {item.layer?.extent && (
                          <Map
                            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                            initialViewState={{ bounds, fitBoundsOptions: { padding: 20 } }}
                            mapStyle="mapbox://styles/mapbox/streets-v11"
                            style={{ height: '200px' }}
                          >
                            <Source type="vector" url={`mapbox://${item.layer?.source_id}`}>
                              <Layer {...{
                                id: item._id,
                                'source-layer': item.layer?.source_layer,
                                type: 'fill',
                                paint: {
                                  'fill-color': '#010E2D',
                                  'fill-opacity': 0.5,
                                },
                              }} />
                            </Source>
                          </Map>
                        )}
                      </div>
                    </div>
                  </li>
                )})}
              </ul>
            </div>
            <div className="content-wrapper">
              <h6>Existing Issue</h6>
              <span style={{ 'cursor': 'pointer'}} onClick={() => setIssuesModalOpen(true)}>
                {request.form?.geohazards && Object.keys(request.form?.geohazards).map((i) => getLabelByValue(geohazards, i)).join(' / ')}
              </span>
            </div>
            <div className="content-wrapper">
              <h6>Existing Data</h6>
              {request.form?.dataFiles.length > 0 && (
                <>
                  <span>Existing data files</span>
                  <ul className="item-list">
                    {request.form?.dataFiles.map((item, index) => (
                      <li key={index} style={{ border: '1px solid #000', borderBottom: '5px' }}>
                        <i className='icon-remove' style={{ cursor: "pointer" }} onClick={() => removeDataFile(item)}></i>
                        <i style={{ cursor: "pointer" }} onClick={() => { setSelectedDataFile(item); setDataFileModalOpen(true); }}>{item.name}</i>
                        <span>{getLabelByValue(dataTypes, item.type)}</span>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              <p>Integrating with Open Data and public sources? {request.form?.openDataIntegration}</p>
              {request.form?.openDataIntegration === 'yes' && (
                <ul>
                  {request.form?.openDataSources.map((ds, i) => <li key={i}>{ds}</li>)}
                </ul>
              )}
              {request.form?.dataURLs.length > 0 && (
                <>
                  <p>Third Party URLs</p>
                  <ul className="item-list">
                    {request.form?.dataURLs.map((item, index) => (
                      <li key={index} style={{ border: '1px solid #000', borderBottom: '5px' }}>
                        <i className='icon-remove' style={{ cursor: "pointer" }} onClick={() => removeDataURL(item)}></i>
                        <i style={{ cursor: "pointer" }} onClick={() => { setSelectedDataURL(item); setDataURLModalOpen(true); }}>{item.name}</i>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
            { user.admin && <button onClick={() => setIsAssessmentModalOpen(true)}>Assessment</button>}<br/>
            <button onClick={() => deleteRequest()} className="outline" style={{ color: '#f0941d', border: 'none' }}>
              <i style={{ color: '#f0941d' }} className='icon-remove'></i> Delete this request
            </button><br />
            <button onClick={() => window.open("mailto:info@titan4.it", "_blank")} className="outline" style={{ margin: '0 10px 50px'}}>
              <i className='icon-help'></i> Report a problem
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestsDetail;
