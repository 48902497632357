import React, { useState } from "react";
import { client } from "../../services/api";
import { toast } from "react-toastify";
import ModalAddStrucure from "../ModalAddStrucure";
import { getLabelByValue, structureTypes } from "../../constants/formOptions";

function StepObjective({ formData, updateFormData }) {
  const [isAddStrucureModalOpen, setIsAddStrucureModalOpen] = useState(false);

  const toggleCheckbox = (value) => {
    updateFormData("selectedObjectives", formData.selectedObjectives.includes(value)
      ? formData.selectedObjectives.filter((item) => item !== value)
      : [...formData.selectedObjectives, value]);
  };

  const addStructure = (structure) => {
    updateFormData("structures", [...formData.structures, structure]);
  };

  const removeStructure = async (structure) => {
    if (structure.dataFile && window.confirm("The structure file uploaded will be deleted, continue?")) {
      await client.deleteGeometry(formData.project, structure.dataFile)
        .then(() => toast.success("Structure file deleted successfully!"));
    }
    updateFormData("structures", formData.structures.filter((item) => item._id !== structure._id));
  };

  return (
    <div className="objective-wrapper">
      <h3>Main Objective</h3>
      <div className="row">
        <div className="col-2">
          <h6>Existing structure</h6>
          <div className="area-container" style={{ display: 'block', padding: '10px', maxWidth: 'none' }}>
            <label>
              <input
                type="checkbox"
                name="mainObjective"
                value="evaluate"
                checked={formData.selectedObjectives.includes("evaluate")}
                onChange={() => toggleCheckbox("evaluate")}
              />
              Evaluate an existing structure
            </label>
            <label>
              <input
                type="checkbox"
                name="mainObjective"
                value="assess"
                checked={formData.selectedObjectives.includes("assess")}
                onChange={() => toggleCheckbox("assess")}
              />
              Assess environmental impact
            </label>
            {(formData.selectedObjectives.includes("evaluate") || formData.selectedObjectives.includes("assess")) && (
              <>
                <ul className="item-list">
                  {formData.structures.map((item, index) => (
                    <li key={index}><i className='icon-remove' onClick={() => removeStructure(item)}></i> {item.name} <span>{getLabelByValue(structureTypes, item.type)}</span></li>
                  ))}
                </ul>
                <button className="action-link" onClick={() => setIsAddStrucureModalOpen(true)}>+ Add a structure</button>
                <ModalAddStrucure projectId={formData.project} isOpen={isAddStrucureModalOpen} onClose={() => setIsAddStrucureModalOpen(false)} onDone={addStructure} />
              </>
            )}
          </div>
          <h6>Context</h6>
          <div className="area-container" style={{ maxWidth: 'none' }}>
            <label>
              <input
                type="checkbox"
                name="mainObjective"
                value="context"
                checked={formData.selectedObjectives.includes("context")}
                onChange={() => toggleCheckbox("context")}
              />
              The environmental context
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepObjective;
