import React, { useState } from "react";
import Modal from "./Modal";

function ModalExpertRequest({ isOpen, onClose, onDone }) {
  const [sensor, setSensor] = useState("");
  const [orbit, setOrbit] = useState("");

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    onDone({ sensor, orbit });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} className="large">
      <div className="modal-add-data-file">
        <h6>Submit Expert Request</h6>
        <div className="form-group">
          <label for="sensor">Sensor:</label>
          <select name="sensor" onChange={(e) => setSensor(e.target.value)}>
            <option>S1</option>
            <option>CSK</option>
            <option>SAOCOM</option>
          </select>
        </div>
        <div className="form-group">
          <label for="orbit">Orbit direction:</label>
          <select name="orbit" onChange={(e) => setOrbit(e.target.value)}>
            <option>ASC</option>
            <option>DESC</option>
          </select>
        </div>
      </div>

      <div className="modal-actions" style={{ justifyContent: 'flex-end' }}>
        <button className="button-outline outline" onClick={handleClose}>
          Cancel
        </button>
        <button className="button-dark" onClick={handleSubmit} disabled>
          Submit
        </button>
      </div>
    </Modal>
  );
}

export default ModalExpertRequest;
