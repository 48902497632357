import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import MenuItem from '../components/MenuItem';
import User from '../images/user.png';
import AuthContext from '../context/AuthContext';

function AccountMenu() {
  const { logout, user } = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <div className="account-menu">
      <div style={{ textAlign: 'center', paddingBottom: 20 }}>
      <img src={User} alt="User" />
      <span style={{ display: 'block' }}>{user.email}</span>
      </div>
      <MenuItem name="Profile" onClick={() => navigate(`/profile`)} />
      <MenuItem name="Requests" onClick={() => navigate(`/requests`)} />
      <MenuItem name="Privacy Policy" onClick={() => navigate(`/privacy-policy`)} />
      <span className="sign-out cursor" onClick={() => logout()}>
        <i className='icon-sign-out'></i>
        <div>Sign Out</div>
      </span>
    </div>
  );
}

export default AccountMenu;