import React, { useState, useRef, useEffect } from 'react';

function Dropdown({ title, inverted, hideArrow, children }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef} style={{ position: 'relative' }}>
      <div
        className={`dropdown-header ${inverted ? 'inverted' : ''}`}
        onClick={toggleDropdown}
        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '5px' }}
      >
        {!hideArrow && inverted && <i className="icon-chevron-down"></i>}
        <div>{title}</div>
        {!hideArrow && !inverted && <i className="icon-chevron-down"></i>}
      </div>
      {isOpen && (
        <div className="dropdown-content" style={{ position: 'absolute', top: '100%', right: 0, background: 'white', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '4px', zIndex: 10 }}>
          {children}
        </div>
      )}
    </div>
  );
}

export default Dropdown;
