import React from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import ContentWrapper from "../components/ContentWrapper";

function PrivacyPolicy() {
  return (
    <div>
      <Header />
      <div className="main-wrapper">
        <Sidebar />
        <div className="main-content">
          <div className="main-content-primary" style={{ padding: '36px 24px' }}>
            <h5>Information on the processing of personal data art. 13-14 Reg.to EU 2016/679</h5>
            <ContentWrapper>
              <p><b>TITAN4 srl</b> (hereinafter <b>"Titan4"</b>), as data controller, provides this information on the processing of personal data pursuant to articles 13 and 14 of
              EU Regulation 2016/679 (GDPR).</p>
              <h6>1. Purposes of the Processing</h6>
              The personal data provided by users will be processed exclusively for:
              <ul>
                <li>To allow access to the functions of the TITAN4 srl platform, according to the required procedures</li>
                <li>Ensure user profile management and application services authorization</li>
                <li>Verify the technological performance of the implemented solution</li>
              </ul>
              No transfer of data to third parties is envisaged for purposes other than those explicitly indicated.
              <h6>2. Types of Personal Data Processed</h6>
              Only the following personal data will be collected and processed:
              <ul>
                <li>Name and surname;</li>
                <li>Business email address;</li>
              </ul>
              This data is classified as "common data" under the GDPR.
              <h6>3. Processing and Storage Methods</h6>
              Personal data:
              <ul>
                <li>They will be stored in the Single Sign-On (SSO) subsystem of TITAN4;</li>
                <li>They will be associated with a unique identifier (GUID) for the management of profiles and authorizations;</li>
                <li>Stored for the period strictly necessary to achieve the purposes indicated above and subsequently securely deleted.</li>
              </ul>
              Data subjects will be informed of the deletion of data, where applicable.
              <h6>4. Rights of Data Subjects</h6>
              Data subjects may exercise the following rights:
              <ul>
                <li>Access to your personal data;</li>
                <li>Correction and updating of data;</li>
                <li>Permanent deletion of data.</li>
              </ul>
              Requests can be sent to the e-mail address: <a href="mailto:gdpr@titan4.it" target="_blank" rel="noreferrer">gdpr@titan4.it</a>.
              <h6>5. Transfers of Data outside the EEA</h6>
              No transfer of personal data outside the European Economic Area (EEA) is envisaged.
              <h6>6. Data Protection Officer (DPO)</h6>
              The internal manager designated by TITAN4 constantly verifies compliance with the rules on the protection of personal data and compliance with the
              GDPR. For more information, you can contact the DPO at the e-mail address: <a href="mailto:gdpr@titan4.it" target="_blank" rel="noreferrer">gdpr@titan4.it</a>.
              <h6>7. Data Retention</h6>
              Personal data will be stored only for the time necessary to achieve the purposes indicated in this policy and subsequently permanently deleted,
              except for legal obligations.
              <h6>8. Changes to this Policy</h6>
              TITAN4 reserves the right to update this policy in the event of regulatory changes or business processes. Changes will be promptly communicated
              to the interested parties.
            </ContentWrapper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
