import React, { useState } from "react";
import { toast } from "react-toastify";
import Modal from "./Modal";
import Editor from 'react-simple-wysiwyg';

function ModalRequestAssessment({ isOpen, onClose, onDone }) {
  const [description, setDescription] = useState("");

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleDone = () => {
    if (!description.trim()) {
      toast.error("Please provide a description.", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    onDone({ description });
    handleClose();
  };

  const resetForm = () => {
    setDescription("");
  };

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} className="large">
      <div className="modal-add-data-file">
        <h6>Submit request assessment</h6>
        <Editor style={{ height: '200px' }} value={description} onChange={(e) => setDescription(e.target.value)} />
      </div>

      <div className="modal-actions" style={{ justifyContent: 'flex-end' }}>
        <button className="button-outline outline" onClick={handleClose}>
          Cancel
        </button>
        <button className="button-dark" onClick={handleDone}>
          Done
        </button>
      </div>
    </Modal>
  );
}

export default ModalRequestAssessment;
