import React from "react";
import Modal from "./Modal";

function LoadingModal({ isOpen, message, current, total }) {
  if (!isOpen) return null;
  return (
    <Modal isOpen={isOpen} hideClose>
      <div className="modal-loading">
        <p style={{ textAlign: 'center' }}>{message}</p>
        <progress style={{ width: '100%', height: '40px', marginBottom: '20px' }} value={current} max={total}></progress>
      </div>
    </Modal>
  );
}

export default LoadingModal;
