import React, { useEffect, useState, useCallback } from "react";
import { client } from "../services/api";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import StepAreaTime from "../components/requestSteps/StepAreaTime";
import StepObjective from "../components/requestSteps/StepObjective";
import StepActivitySelection from "../components/requestSteps/StepActivitySelection";
import StepExistingIssue from "../components/requestSteps/StepExistingIssue";
import StepExistingData from "../components/requestSteps/StepExistingData";
import StepConfirm from "../components/requestSteps/StepConfirm";
import ConfirmationPage from "../components/ConfirmationPage";
import ModalExpertRequest from "../components/ModalExpertRequest";
import LoadingModal from "../components/LoadingModal";
import { Stepper } from "react-form-stepper";
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function NewRequest() {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step1Valid, setStep1Valid] = useState(false);
  const [confirmationData, setConfirmationData] = useState(null);
  const [isExpertRequestModalOpen, setIsExpertRequestModalOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(null);

  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    // Step 1: Activity & Request Selection
    project: "", // Selected activity
    requestType: [],

    // Step 2: Area & Time
    areaOfInterestType: "",
    areaOfInterest: null,
    requestActivityAssessment: "",
    timeOfInterestAssessment: { from: "", to: "" }, // Start and end time
    requestActivityMonitoring: "",
    timeOfInterest: { from: "", to: "" }, // Start and end time
    recurrenceInterval: "",
    recurrenceType: "",

    // Step 3: Objective
    selectedObjectives: [],
    structures: [],

    // Step 4: Existing Issue
    geohazards: {}, // Array of selected geohazards

    // Step 5: Existing Data
    existingData: "no", // Either 'yes' or 'no'
    dataFiles: [], // Array of selected data files
    openDataIntegration: "no", // Either 'yes' or 'no'
    openDataSources: [], // Array of data sources
    thirdPartyFiles: "no", // Either 'yes' or 'no'
    dataURLs: [], // Array of data urls

    // Final: Confirm
    requestPriority: "medium",
  });

  const steps = [
    { title: "Project & Request", component: StepActivitySelection },
    { title: "Area & Time", component: StepAreaTime },
    { title: "Objective", component: StepObjective },
    { title: "Existing Issue", component: StepExistingIssue },
    { title: "Existing Data", component: StepExistingData },
    { title: "Confirm", component: StepConfirm },
  ];

  const updateProgress = (message, current, total) => {
    setUploadProgress({
      message,
      current,
      total,
    });
  };

  const validateStep1 = useCallback(() => {
    if (!formData.areaOfInterest) {
      return { valid: false, error: "Please set the Area of Interest before proceeding." };
    }
    if (!formData.requestActivityAssessment && !formData.requestActivityMonitoring) {
      return { valid: false, error: "Please set both 'From' and 'To' dates for Time of Interest." };
    }
    if (formData.requestActivityAssessment) {
      const { from, to } = formData.timeOfInterestAssessment;
      if (!from || !to) {
        return { valid: false, error: "Please set both 'From' and 'To' dates for Time of Interest." };
      }
      if (new Date(from) >= new Date(to)) {
        return { valid: false, error: "'From' date must be earlier than 'To' date." };
      }
    }
    if (formData.requestActivityMonitoring) {
      const { from, to } = formData.timeOfInterest;
      if (!from || !to) {
        return { valid: false, error: "Please set both 'From' and 'To' dates for Time of Interest." };
      }
      if (new Date(from) >= new Date(to)) {
        return { valid: false, error: "'From' date must be earlier than 'To' date." };
      }
    }
    return { valid: true };
  }, [formData]);

  useEffect(() => {
    const validation = validateStep1();
    setStep1Valid(validation.valid);
  }, [formData, validateStep1]);

  const handleNext = () => {
    if (currentStep === 0) {
      if (!formData.project) {
        toast.error("Please select a project.");
        return;
      }
    }
    if (currentStep === 1) {
      const validation = validateStep1();
      if (!validation.valid) {
        toast.error(validation.error);
        return;
      }
    }

    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleExpertRequest = () => {
    setIsExpertRequestModalOpen(true);
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    updateProgress('', 0, 0);
    try {
      const updatedFormData = { ...formData };
      const totalStructures = updatedFormData.structures.length;
      const totalDataFiles = updatedFormData.dataFiles.length;
      const totalFiles = totalStructures + totalDataFiles;

      for (let i = 0; i < totalStructures; i++) {
        const structure = updatedFormData.structures[i];
        if (structure.imageIssueFile) {
          updateProgress(`Uploading structure ${i + 1}/${totalStructures} issue image...`, i + 1, totalFiles);
          const uploadResponse = await client.uploadFile(structure.imageIssueFile);
          structure.imageIssueFile = uploadResponse.inserted_id;
        }
      }
  
      // Upload files in dataFiles
      for (let i = 0; i < totalDataFiles; i++) {
        const dataFile = updatedFormData.dataFiles[i];
        if (dataFile.upload) {
          updateProgress(`Uploading data file ${i + 1}/${totalDataFiles}...`, totalStructures + i + 1, totalFiles);
          const uploadResponse = await client.uploadFile(dataFile.upload);
          dataFile.upload = uploadResponse.inserted_id;
        }
      }

      // Submit updated form data
      const response = await client.createRequest({ form: updatedFormData });
      if (response.status === 201) {
        setConfirmationData(response.data);
      } else {
        toast.error(response.data.error || "Submission failed. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting request:", error);
      toast.error(`Error: ${error.message}`);
    } finally {
      setIsSubmitting(false);
      setUploadProgress(null);
    }
  };

  const updateFormData = (field, value) => {
    setFormData((prevFormData) => ({ ...prevFormData, [field]: value }));
  };

  const CurrentStepComponent = steps[currentStep].component;

  return (
    <div className="activity">
      <Header />
      <div className="main-wrapper">
        <Sidebar />
        <div className="main-content new-request-wrapper">
          <ToastContainer position="top-right" autoClose={5000} />
          { confirmationData ? (
            <ConfirmationPage confirmationData={confirmationData} />
          ) : (
            <div className="new-request">
              <h2>New Request</h2>
              <Stepper steps={steps.map((s) => ({ label: s.title }))}
                activeStep={currentStep}
                className='stepper'
                stepClassName='step'
                connectorStateColors={true} styleConfig={{
                  inactiveBgColor: '#C5CBD7',
                  activeBgColor: '#C5CBD7',
                  completedBgColor: '#010E2D',
                  size: '40px',
                  borderRadius: '100%',
                }} connectorStyleConfig={{
                  disabledColor: '#C5CBD7',
                  activeColor: '#010E2D',
                  completedColor: '#010E2D',
                  size: '3px',
                }}/>
              <div className="step-content">
                <CurrentStepComponent formData={formData} updateFormData={updateFormData} setCurrentStep={setCurrentStep} />
              </div>
            </div>
          )}
          <div className="navigation">
            <div className="navigation-left">
              <button className="link" onClick={() => navigate("/")} disabled={isSubmitting}>
                Back To Projects
              </button>
            </div>
            { !confirmationData && (
              <div className="navigation-right">
                {currentStep === 1 && (
                  <>
                    <button style={{ width: 'auto', padding: '10px' }} className="round" onClick={handleExpertRequest} disabled={!step1Valid}>
                      Manual Expert User Request
                    </button>
                    <ModalExpertRequest isOpen={isExpertRequestModalOpen} onClose={() => setIsExpertRequestModalOpen(false)} onDone={handleExpertRequest} />
                  </>
                )}
                <button className="round" onClick={handleBack} disabled={currentStep === 0 || isSubmitting}>
                  <i className="icon-chevron-left"></i>
                </button>
                {currentStep < steps.length - 1 ? (
                  <button className="round" onClick={handleNext} disabled={isSubmitting}>
                    <i className="icon-chevron-right"></i>
                  </button>
                ) : (
                  <>
                    <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                      {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
                    </Button>
                    <LoadingModal
                      isOpen={!!uploadProgress}
                      message={uploadProgress?.message}
                      current={uploadProgress?.current}
                      total={uploadProgress?.total}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewRequest;
